import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  color: '#eee',
  name: '',
  last_link: '',
  url: '',
  is_published: false,
  moduleList: []
};

const project = createSlice({
  name: 'project',
  initialState,
  reducers: {
    createModule(state, action) {
      state.moduleList.push(action.payload);
    },
    removeModule(state, action) {
      let id = action.payload;
      state.moduleList = state.moduleList.filter(data => data.id !== id);
    },
    addModuleData(state, action) {
      let moduleData = action.payload;

      state.moduleList.map((module) => {
        if (module.id === moduleData.id) {
          module.data = moduleData.data;
        }
      });
    },
    setProjectData(state, action) {
      let data = action.payload;
      Object.keys(data).map((prop) => state[prop] = data[prop]);
    },
    clearProject(state) {
      state = initialState;
    }
  }
});

export default project.reducer;
export const {
  createModule,
  removeModule,
  addModuleData,
  setProjectData,
  clearProject
} = project.actions;

