import React from 'react';
import styled from 'styled-components';
import {PhotoModule} from "../Page/Photo";

export const Photo = ({number, statData, projectData}) => {
  let topNameList = projectData?.topNameList || [];
  let bottomNameList = projectData?.bottomNameList || [];
  let topData = statData.topData || [];
  let bottomData = statData.bottomData || [];

  return (
    <StyledContainer>
      <StyledModule>
        <PhotoModule data={projectData} previewMode={true}/>
      </StyledModule>
      <StyledItemContainer>
        <div>
          <h4>Top photo</h4>
          {
            topData.map((value, i) => {
              return (
                <StyledItem key={`${number}_${i}`}>
                  <div>{`${topNameList[i]}:` || `Item ${i + 1}:`}</div>
                  <div>{value}%</div>
                </StyledItem>
              )
            })
          }
        </div>
        <div>
          <h4>Bottom photo</h4>
          {
            bottomData.map((value, i) => {
              return (
                <StyledItem key={`${number}_${i}`}>
                  <div>{`${bottomNameList[i]}:` || `Item ${i + 1}:`}</div>
                  <div>{value}%</div>
                </StyledItem>
              )
            })
          }
        </div>
      </StyledItemContainer>
    </StyledContainer>
  );
};
const StyledModule = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  background: ${props => props.backgroundColor ? props.backgroundColor : '#fff'};
`;

const StyledContainer = styled.div`
  padding: 20px 10px;
  width: 300px;
`;

const StyledImg = styled.img`
  width: 100%;
  margin-bottom: 20px;
`;

const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > div {
    width: 100%;
  }

  & h4 {
    margin: 10px 0 5px;
  }
`;

const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0;
  & > div {
    font-size: 15px;
    line-height: 19px;
  }
  & > div:first-child {
    width: 70%;
    padding-right: 10px;
  }
`;
