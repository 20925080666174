import React from 'react';
import {Button} from '@mui/material';
import styled from 'styled-components';

export const LinkButton = ({onClick, text}) => {
  return (
    <StyledButton variant="text" onClick={onClick}>
      {text}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  && {
    width: 100%;
    text-transform: none;
    text-decoration: underline;
    &:hover {
      background: transparent;
      text-decoration: underline;
    }
  }
`;
