import React, {useContext} from 'react';
import styled from "styled-components";
import {Link as RouterLink} from 'react-router-dom';
import {IconButton, Tooltip} from '@mui/material';
import {Home, Logout} from '@mui/icons-material';
import {AuthContext} from '../../context';

export const Left = () => {
  const {isAuth, setIsAuth} = useContext(AuthContext);

  const logout = () => {
    setIsAuth(false);
    localStorage.removeItem('tkn');
  };

  return (
    <StyledLeft className="title">
      <IconButton component={RouterLink} to="/" sx={{ color: "#fff" }} aria-label="home">
        <Home fontSize="large" />
      </IconButton>
      <Tooltip title="Log out">
        <IconButton onClick={logout} sx={{ color: "#fff", transform: "scaleX(-1)" }} aria-label="logout">
          <Logout fontSize="large" />
        </IconButton>
      </Tooltip>
    </StyledLeft>
  );
};

const StyledLeft = styled.div`
  width: 85px;
  height: calc(100vh - 4%);
  padding: 2% 0;
  background: #353A3F;
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;


