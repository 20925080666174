import React, {useRef, useState, useMemo, useEffect, useDeferredValue} from 'react';
import styled from 'styled-components';
import {DraggableBox} from '../../UI/DraggableBox';
import {useDispatch, useSelector} from 'react-redux';
import {addModuleData} from '../../../redux/project';

export const TitleVisualModule = ({moduleName, number, id, className, onUpdateFields, onRemoveModule}) => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.project.moduleList).find(module => module.id === id)?.data || {};

  const [textBoxPosition, setTextBoxPosition] = useState(data.textBoxPosition || [25, 20]);
  const [textBoxSize, setTextBoxSize] = useState(data.textBoxSize || [50, 20]);
  const [textBoxColor, setTextBoxColor] = useState(data.textBoxColor || '#333');
  const [boxText, setBoxText] = useState(data.boxText || 'Changing placeholder text here');
  const [buttonText, setButtonText] = useState(data.buttonText || 'BUTTON TEXT');
  const [backgroundColor, setBackgroundColor] = useState(data.backgroundColor || '#fff');
  const [backgroundData, setBackgroundData] = useState(data.backgroundData || null);
  const [buttonPosition, setButtonPosition] = useState(data.buttonPosition || [35, 60]);
  const [buttonSize, setButtonSize] = useState(data.buttonSize || [30, 15]);
  const [fontFamily, setFontFamily] = useState(data.fontFamily || 'Poppins-Regular');
  const [buttonFontSize, setButtonFontSize] = useState(data.buttonFontSize || 3);
  const [textBoxFontSize, setTextBoxFontSize] = useState(data.textBoxFontSize || 3);
  const [buttonColor, setButtonColor] = useState(data.buttonColor || '#eee');
  const [buttonTextColor, setButtonTextColor] = useState(data.buttonTextColor || '#333');
  const [buttonRadius, setButtonRadius] = useState(data.buttonRadius || 5);

  const container = React.useRef(null);
  const boxTextRef = useRef(boxText);
  const buttonTextRef = useRef(buttonText);

  const [textBoxLinePosition, setTextBoxLinePosition] = useState([0, 0]);
  const [buttonLinePosition, setButtonLinePosition] = useState([0, 0]);

  const [moduleWidthCoef, setModuleWidthCoef] = useState(0);
  const defferedCoef = useDeferredValue(moduleWidthCoef);

  const memoBackgroundImage = useMemo(() => {
    return backgroundData ? `url('${backgroundData.image}')` : null;
  }, [backgroundData]);

  function removeModule() {
    onRemoveModule(id);
  }

  function updateFields() {
    onUpdateFields({
      moduleName,
      number,
      id,
      textBoxColor,
      textBoxFontSize,
      fontFamily,
      buttonFontSize,
      buttonColor,
      buttonTextColor,
      buttonRadius,
      setButtonRadius,
      setTextBoxColor,
      setTextBoxFontSize,
      setFontFamily,
      setButtonFontSize,
      setButtonColor,
      setButtonTextColor,
      setBackgroundData,
      backgroundData,
      backgroundColor,
      setBackgroundColor,
      removeModule
    });
  }

  useEffect(updateFields, [textBoxColor, textBoxFontSize, fontFamily, buttonFontSize, buttonColor, buttonTextColor, buttonRadius, backgroundData, backgroundColor]);

  useEffect(() => {
    dispatch(addModuleData({
      id: id,
      data: {
        textBoxPosition,
        textBoxSize,
        textBoxColor,
        buttonPosition,
        boxText,
        buttonText,
        backgroundData,
        backgroundColor,
        buttonColor,
        buttonTextColor,
        buttonRadius,
        buttonSize,
        fontFamily,
        buttonFontSize,
        textBoxFontSize
      }
    }));
  }, [
    textBoxPosition,
    textBoxSize,
    textBoxColor,
    buttonPosition,
    boxText,
    buttonText,
    backgroundData,
    backgroundColor,
    buttonColor,
    buttonTextColor,
    buttonRadius,
    buttonSize,
    fontFamily,
    buttonFontSize,
    textBoxFontSize
  ]);

  function onChangeSize(e) {
    let value =  container.current.offsetWidth / window.innerWidth;
    setModuleWidthCoef(value);
  }

  useEffect(() => {
    onChangeSize();
    window.addEventListener('resize', onChangeSize);
    return function () {
      window.removeEventListener('resize', onChangeSize);
    }
  }, []);

  return (
    <StyledModule backgroundImage={memoBackgroundImage} ref={container} className={className} onClick={updateFields}>
      <DraggableBox
        position={textBoxPosition}
        setPosition={setTextBoxPosition}
        sizes={textBoxSize}
        setSizes={setTextBoxSize}
        linePosition={textBoxLinePosition}
        setLinePosition={setTextBoxLinePosition}
        parentRef={container}
      >
        <StyledText
          fontFamily={fontFamily}
          font={textBoxFontSize * defferedCoef}
          color={textBoxColor}
          onInput={(e) => setBoxText(e.target.innerHTML)}
          contentEditable
          dangerouslySetInnerHTML={{__html: boxTextRef.current}}
        ></StyledText>
      </DraggableBox>

      <DraggableBox
        position={buttonPosition}
        setPosition={setButtonPosition}
        sizes={buttonSize}
        setSizes={setButtonSize}
        linePosition={buttonLinePosition}
        setLinePosition={setButtonLinePosition}
        parentRef={container}
      >
        <StyledButton
          fontFamily={fontFamily}
          font={buttonFontSize * defferedCoef}
          textColor={buttonTextColor}
          radius={buttonRadius}
          color={buttonColor}
          onInput={(e) => setButtonText(e.target.innerText)}
          contentEditable
          dangerouslySetInnerHTML={{__html: buttonTextRef.current}}
        ></StyledButton>
      </DraggableBox>

    </StyledModule>
  );
};

const StyledModule = styled.div`
  position: absolute;
  top: 5%;
  left: 5%;
  bottom: 5%;
  right: 5%;
  background: ${props => props.backgroundImage ? props.backgroundImage : '#fff'};
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  box-shadow: 0 0 2px 1px rgb(0 0 0 / 30%);
  &.active {
    box-shadow: 0 0 1px 2px #53A451;
  }
`;

const StyledText = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  background: transparent;
  font-family: ${props => props.fontFamily};
  font-size: ${props => 0.9 * props.font + 'vw'} !important;
  line-height: 1.2;
  color: ${props => props.color};
`;

const StyledButton = styled.div`
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background: ${props => props.color};
  color: ${props => props.textColor};
  border: 3px solid ${props => props.textColor};
  border-radius: ${props => props.radius + 'px'};
  font-family: ${props => props.fontFamily};
  font-size: ${props => 0.5 * props.font + 'vw'} !important;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &:focus-visible {
    outline: none;
  }
`;
