import React from 'react';
import styled from 'styled-components';
import {SliderModule} from "../Page/Slider";

export const Slider = ({number, statData, projectData}) => {
  let sliderNameList = projectData?.sliderNameList || [];
  return (
    <StyledContainer>
      <StyledModule>
        <SliderModule data={projectData} previewMode={true}/>
      </StyledModule>
      {
        statData.map((value, i) => {
          return (
            <StyledItemContainer>
              <StyledItem key={`slider_${number}_${i}`}>
                <div>{sliderNameList[i] || `Item ${i + 1}`}:</div>
                <div>{typeof value === 'number' ? value : value.value}%</div>
              </StyledItem>
              {value.options &&
                <StyledItemOptionContainer>
                  <h5>Options</h5>
                  {
                    value.options?.map((option, j) => {
                      return <div>{option.name} - <span>Count</span>: {option.count} | {option.percents}%</div>
                    })
                  }
                </StyledItemOptionContainer>
              }
            </StyledItemContainer>
          )
        })
      }
    </StyledContainer>
  );
};
const StyledModule = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  background: ${props => props.backgroundColor ? props.backgroundColor : '#fff'};
`;
const StyledContainer = styled.div`
  padding: 20px 10px;
  width: 300px;
`;

const StyledImg = styled.img`
  width: 100%;
  margin-bottom: 20px;
`;

const StyledItemContainer = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  border-bottom: 1px black solid;
`;

const StyledItemOptionContainer = styled.div`
  padding: 0 10%;
  > div > span {
      color: #0a0a0a;
      font-weight: 500;
  }
`;

const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
`;
