import axios from 'axios';

let url = 'https://api.tallymade.tech.anroit.com/api';

if (process.env.NODE_ENV === 'production') {
  url = `https://api.${window.location.host}/api`;
}

export default class Service {
  static setAccessToken(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  static async getProjects() {
    const response = await axios.get(`${url}/projects`);
    return response;
  }

  static async login(data) {
    const response = await axios.post(`${url}/login`, data);
    return response;
  }

  static async setProject(data) {
    const response = await axios.post(`${url}/set_project`, data);
    return response;
  }

  static async copyProject(id) {
    const response = await axios.post(`${url}/copy_project/${id}`);
    return response;
  }

  static async getProject(id) {
    const response = await axios.get(`${url}/get_project/${id}`);
    return response;
  }

  static async updateProject(id, data) {
    const response = await axios.put(`${url}/update_project/${id}`, data);
    return response;
  }

  static async deleteProject(id) {
    const response = await axios.delete(`${url}/destroy_project/${id}`);
    return response;
  }

  static async setStatisticsByProject(id, data) {
    const response = await axios.post(`${url}/set_statistics/${id}`, data);
    return response;
  }

  static async getStatisticsByProject(id) {
    const response = await axios.get(`${url}/get_statistics/${id}`);
    return response;
  }

  static async getParametersEmojiByProject(id, emoji) {
    const response = await axios.get(`${url}/get_parameters_emoji/${id}/${emoji}`);
    return response;
  }

  static async getParametersTextByProject(id, moduleId) {
    const response = await axios.get(`${url}/get_parameters_text_by_project/${id}/`);
    return response;
  }

  static async setPublicChoice(choiceId) {
    const response = await axios.post(`${url}/set_public_parameters/${choiceId}/`);
    return response;
  }

  static async getParametersTextByProjectAndModuleId(id, moduleId) {
    const response = await axios.get(`${url}/get_parameters_text_by_module/${id}/${moduleId}/`);
    return response;
  }

  static async setImageByProject(id, data) {
    const response = await axios.post(`${url}/set_image/${id}`, data);
    return response;
  }

  static async deleteImageByProject(id, data) {
    const response = await axios.post(`${url}/delete_image/${id}`, data);
    return response;
  }

  static async copyImagesBetweenProjects(data) {
    const response = await axios.post(`${url}/copy_images`, data);
    return response;
  }
}
