import React from 'react';
import styled from 'styled-components';
import {Slider as MuiSlider, Typography} from '@mui/material';

export const Slider = ({name, min = 1, max = 15, onChangeValue, value}) => {
  return (
    <StyledSlider>
      <Typography id="non-linear-slider" gutterBottom>{name}</Typography>
      <StyledRange
        value={value}
        min={min}
        step={1}
        max={max}
        onChange={onChangeValue}
        valueLabelDisplay="auto"
        aria-labelledby="non-linear-slider"
      />
    </StyledSlider>
  );
};

const StyledSlider = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 92%;
  margin: auto;
`;

const StyledRange = styled(MuiSlider)`
  width: 92% !important;
  margin: auto;
`;
