import React, {useState, useRef} from 'react';
import styled from 'styled-components';
import {Button} from '@mui/material';
import {Switch} from './UI/Switch';
import {SketchPicker} from 'react-color';
import {useDispatch, useSelector} from 'react-redux';
import {setProjectData} from '../redux/project';
import {useFetching} from '../hooks/useFetching';
import Service from '../API/service';
import {Loader} from '../components/UI/Loader';

export const SaveModal = ({setVisible}) => {
  const data = useSelector(state => state.project);
  const name = useSelector(state => state.project.name);
  const last_link = useSelector(state => state.project.last_link);
  const url = useSelector(state => state.project.url);
  const color = useSelector(state => state.project.color);
  const is_published = useSelector(state => state.project.is_published);

  const dispatch = useDispatch();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const nameRef = useRef(name);
  const linkRef = useRef(last_link);
  const urlRef = useRef(url);

  const [saveProject, isLoading, error] = useFetching(async () => {
    if (url !== urlRef.current) {
      await Service.setProject(JSON.parse(JSON.stringify(data).replaceAll(`/projects/${urlRef.current}/`, `/projects/${url}/`)));
      await Service.copyImagesBetweenProjects({
        fromUrl: urlRef.current,
        toUrl: url
      });
      await Service.deleteProject(urlRef.current);
      document.location.href = `/project-editor/${url}`;
    } else {
      await Service.setProject(data);
    }

    setVisible(false);
  });

  function handleChangeColor(color) {
    color = color.rgb;
    let rgba = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
    dispatch(setProjectData({color: rgba}));
  }

  return (
    <StyledModal onClick={() => setVisible(false)}>
      <StyledModalContent onClick={(e) => e.stopPropagation()}>
        <div>
          <h3>Name project</h3>
          <StyledName
            onInput={(e) => dispatch(setProjectData({name: e.target.innerText}))}
            contentEditable
            dangerouslySetInnerHTML={{__html: nameRef.current}}
          ></StyledName>
        </div>

        <div>
          <h3>Choose custom slug for project</h3>
          <StyledUrl>tallymade.com/project/<span
            onInput={(e) => dispatch(setProjectData({url: encodeURIComponent(e.target.innerText)}))}
            onKeyPress={(e) => {
              if (/^[a-z0-9_]+$/.test(e.key) === false) {
                e.preventDefault();
              }
            }}
            contentEditable
            dangerouslySetInnerHTML={{__html: urlRef.current}}
          ></span></StyledUrl>
        </div>

        <div>
          <h3>Choose custom color for project dashboard</h3>
          <StyledColor>

            <StyledButtonColor onClick={() => setDisplayColorPicker(!displayColorPicker)}>
              <StyledButtonBox bg={color}></StyledButtonBox>
              <StyledButtonText>Select Color</StyledButtonText>
            </StyledButtonColor>
            <StyledColorValue>{color}</StyledColorValue>
            {
              displayColorPicker ?
                <ColorPickerPopover>
                  <ColorPickerCover onClick={() => setDisplayColorPicker(false)}/>
                  <SketchPicker color={color} onChange={handleChangeColor} />
                </ColorPickerPopover>
              : null
            }

          </StyledColor>
        </div>

        <div>
          <h3>Last button link</h3>
          <StyledLink
            onInput={(e) => dispatch(setProjectData({last_link: e.target.innerText}))}
            contentEditable
            dangerouslySetInnerHTML={{__html: linkRef.current}}
          ></StyledLink>
        </div>

        <div>
          <h3>Published?</h3>
          <Switch checked={is_published} setChecked={(checked) => dispatch(setProjectData({is_published: checked}))}/>
        </div>

        <StyledButtonContainer>
          <Button onClick={saveProject} disabled={isLoading ? true: false} variant="contained">
            {isLoading
              ? <Loader width="30px" height="30px" color="#fff"/>
              : 'SAVE'
            }
          </Button>
        </StyledButtonContainer>

      </StyledModalContent>
    </StyledModal>
  );
};

const StyledModal = styled.div`
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledColor = styled.div`
  display: flex;
  position: relative;
`;

const StyledButtonColor = styled.button`
  display: flex;
  align-items: center;
  width: 200px;
  padding: 0;
  border-radius: 6px;
  background: #fff;
  cursor: pointer;
  overflow: hidden;
`;

const StyledButtonBox = styled.span`
  width: 70px;
  height: 40px;
  background: ${props => props.bg};
`;

const StyledButtonText = styled.span`
  margin-left: 13px;
  font-size: 18px;
`;

const ColorPickerCover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ColorPickerPopover = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  top: 50px;
`;

const StyledColorValue = styled.div`
  width: 275px;
  margin-left: 3px;
  padding: 0 8px;
  display: flex;
  padding: 0 8px;
  align-items: center;
  justify-content: flex-start;
`;

const StyledModalContent = styled.div`
  width: 600px;
  height: 480px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 30px 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    margin: 7px 0;
  }
  > div {
    margin: 0;
  }
`;

const StyledName = styled.div`
  width: 250px;
  font-size: 25px;
  line-height: 38px;
  border: 1px solid #eee;
  background: #F4F4F4;
`;

const StyledLink = styled.div`
  width: 500px;
  font-size: 25px;
  line-height: 38px;
  height: 38px;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid #eee;
  background: #F4F4F4;
`;

const StyledUrl = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 25px;
  line-height: 38px;
  span {
    width: 275px;
    background: #F4F4F4;
    margin-left: 3px;
    padding: 0 8px;
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  button {
    width: 180px;
    height: 50px;
    font-size: 21px;
  }
`;
