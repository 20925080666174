import React, {useState, useRef} from 'react';
import styled from 'styled-components';
import img from '../assets/images/parts/splash.gif';

export const LandscapeModal = () => {

  return (
    <StyledModal>
      <h3>This experience is best in landscape mode</h3>
      <img src={img} alt="landscape image"/>
    </StyledModal>
  );
};

const StyledModal = styled.div`
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3 {
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    margin: 10px;
    text-align: center;
    color: #DE5834;
  }
  img {
    height: 360px;
  }
`;
