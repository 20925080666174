import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {PageFrame} from '../components/parts/PageFrame';
import {HeaderReport} from '../components/parts/HeaderReport';
import Service from '../API/service';
import {useFetching} from '../hooks/useFetching';
import {Loader} from '../components/UI/Loader';
import {Slider} from '../components/ModuleList/Statistics/Slider';
import {Photo} from '../components/ModuleList/Statistics/Photo';
import {Emoji} from '../components/ModuleList/Statistics/Emoji';
import {Text} from '../components/ModuleList/Statistics/Text';

const statisticsModules = {
  Slider,
  Photo,
  Emoji,
  Text,
};

export const ProjectReport = () => {
  const { projectId } = useParams();
  const syncRef = useRef(false);
  const [statData, setStatData] = useState(null);
  const [projectData, setProjectData] = useState(null);

  const [loadData, isLoading, error] = useFetching(async () => {
    const statistics = await Service.getStatisticsByProject(projectId);
    const project = await Service.getProject(projectId);

    setStatData(statistics.data);
    setProjectData(project.data.moduleList);
  });

  useEffect(() => {
    if (syncRef.current === false) {
      syncRef.current = true;
      loadData();
    }
  }, []);

  return (
    <StyledStat>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <h1>Statistics not found</h1>
      ) : (
        statData && (
          <PageFrame header={<HeaderReport id={statData.url} />}>
            <StyledContent>
              <Project color={statData.color}>
                <ProjectHeader>
                  <ProjectHeaderLeft>
                    <div>{statData.name}</div>
                    <div>Published {statData.created_at}</div>
                  </ProjectHeaderLeft>
                  <ProjectHeaderCenter>
                    <div>Engagements</div>
                    <div>{statData.engagements}</div>
                  </ProjectHeaderCenter>
                  <ProjectHeaderRight>
                    <div>Pageviews</div>
                    <div>{statData.pageviews}</div>
                  </ProjectHeaderRight>
                </ProjectHeader>
                <ProjectStatistics>
                  {statData.statistics && projectData
                    ? statData.statistics.map((item, i) => {
                        const projectDataItem = projectData.find((i) => i.id === item.id);
                        if (!projectDataItem || !projectDataItem.data) {
                          return null;
                        }
                        const Module = statisticsModules[item.name];
                        return (
                          <ProjectStatisticsItem key={`${i}${item.id}`}>
                            <Module statData={item.data} projectData={projectDataItem.data} moduleId={item.id} engagements={statData.engagements}/>
                          </ProjectStatisticsItem>
                        );
                      })
                    : 'No stats yet'}
                </ProjectStatistics>
              </Project>
            </StyledContent>
          </PageFrame>
        )
      )}
    </StyledStat>
  );
};

const StyledStat = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
`;

const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #e5e5e5;
`;

const Project = styled.div`
  border-radius: 10px;
  background: #fff;
  min-height: 410px;
  border-top: 15px solid ${props => `${props.color}`};
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  margin: 2% 5%;
  overflow: hidden;
`;

const ProjectHeader = styled.div`
  background: #f4f6f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  > div > div {
    height: 25px;
    display: flex;
    align-items: center;
  }
`;

const ProjectHeaderLeft = styled.div`
  > div:first-child {
    font-weight: 700;
    font-size: 1.3em;
    color: #000000;
  }
`;

const ProjectHeaderCenter = styled.div`
  width: 20%;
  > div:nth-child(2) {
    font-weight: 700;
    font-size: 1.1em;
  }
`;

const ProjectHeaderRight = styled.div`
  width: 20%;
  > div:nth-child(2) {
    font-weight: 700;
    font-size: 1.1em;
  }
`;

const ProjectStatistics = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 3%;
`;

const ProjectStatisticsItem = styled.div``;
