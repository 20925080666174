import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Service from '../../API/service';
import { useFetching } from '../../hooks/useFetching';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../components/UI/Loader';

export const InputFileButton = ({ id, onChange, value, text }) => {
  const inputRef = useRef(null);
  const url = useSelector(state => state.project.url);
  const data = useSelector(state => state.project);
  const projectSaving = useRef(false);
  let imageUrl = 'https://api.tallymade.tech.anroit.com/storage/projects';

  if (process.env.NODE_ENV === 'production') {
    imageUrl = `https://api.${window.location.host}/storage/projects`;
  }

  const [loadingImage, isLoading, errorLoading] = useFetching(async () => {
    let file = inputRef.current.files[0];

    if (file) {
      if (value?.name) {
        await deleteImage();
      }

      let formData = new FormData();
      formData.append('image', file);

      const responseImage = await Service.setImageByProject(url, formData);

      if (responseImage.status === 200) {
        onChange({
          name: responseImage.data,
          image: `${imageUrl}/${url}/${responseImage.data}`
        });
        projectSaving.current = true;
      }
    }
  });

  const [deleteImage, isDeletion, errorDelete] = useFetching(async () => {
    const responseImage = await Service.deleteImageByProject(url, {
      image: value.name
    });

    if (responseImage.status === 200) {
      onChange(null);
      inputRef.current.value = '';
      projectSaving.current = true;
    }
  });

  useEffect(() => {
    if (projectSaving.current === false) {
      return;
    }

    projectSaving.current = false;
    Service.setProject(data);
  }, [value]);

  return (
    <StyledButton key={id}>
      <StyledLabel>
        <StyledInput
          ref={inputRef}
          type='file'
          accept='image/*'
          onChange={loadingImage}
          disabled={isLoading || isDeletion ? true : false}
        />
        {isLoading || isDeletion ? (
          <Loader margin='0' width='15px' height='15px' color='#fff' />
        ) : (
          text
        )}
      </StyledLabel>
      {value && (
        <StyledName>
          <span>{value.name}</span>
          <IconButton onClick={deleteImage} sx={{ color: '#fff', width: '20px', height: '20px' }}>
            <DeleteForeverIcon sx={{ fontSize: 18 }} />
          </IconButton>
        </StyledName>
      )}
    </StyledButton>
  );
};

const StyledButton = styled.div`
  user-select: none;
  color: #fff;
  margin: 20px 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  border: 1px solid #ccc;
  display: block;
  padding: 12px;
  cursor: pointer;
  text-align: center;
  border-radius: 6px;
  margin: auto;
  width: 100%;
  box-sizing: border-box;
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledName = styled.div`
  font-size: 14px;
  padding: 5px 0 0;
  width: calc(100% - 10px);
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
