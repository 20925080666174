import React, {useState, useRef} from 'react';
import styled from 'styled-components';
import {Button} from '@mui/material';
import {useFetching} from '../hooks/useFetching';
import {Loader} from '../components/UI/Loader';
import Service from '../API/service';
import {useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";

export const DeleteModal = ({setVisible}) => {
  const name = useSelector(state => state.project.name);
  const url = useSelector(state => state.project.url);
  const navigate = useNavigate();

  const [deleteProject, isProjectsLoading, error] = useFetching(async () => {
    const response = await Service.deleteProject(url);
    navigate("/projects");
  });

  return (
    <StyledModal onClick={() => setVisible(false)}>
      <StyledModalContent onClick={(e) => e.stopPropagation()}>
        <div>
          <h3>Delete project {name}?</h3>
        </div>

        <StyledButtonContainer>
          <Button onClick={() => setVisible(false)} variant="contained">CANCEL</Button>
          <Button onClick={deleteProject} variant="contained">DELETE</Button>
        </StyledButtonContainer>
      </StyledModalContent>
    </StyledModal>
  );
};

const StyledModal = styled.div`
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledModalContent = styled.div`
  width: 500px;
  height: 180px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 30px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  h3 {
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    margin: 15px 0 13px;
  }
  > div {
    margin: 0;
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  button {
    width: 180px;
    height: 50px;
    font-size: 21px;
  }
`;
