import React, {createRef, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {useParams} from "react-router-dom";

export const EmojiDetail = ({dataChoice, project}) => {
    const {moduleId} = useParams();
    const projectEmojiData = project.data.moduleList.find((item) => item.id === moduleId).data

    const imageList = projectEmojiData.emojiList.reduce((item, data) => {
        item[data.name] = data.imageData.image;
        return item;
    }, {});

    const mapImage = projectEmojiData.backgroundBox?.image ?? projectEmojiData.backgroundData?.image;
    const mapName = projectEmojiData.backgroundBox?.name;
    const [statDataChoice, setDataChoice] = useState(dataChoice);
    const mapRef = useRef(dataChoice.map(() => createRef()));
    const canvasRenderRef = useRef(false);
    let minWidth = 300;

    let wCoef = (projectEmojiData.boxSize[0] * 16) / 100;
    let hCoef = (projectEmojiData.boxSize[1] * 9) / 100;
    let width = minWidth;
    let height = (minWidth / wCoef) * hCoef;
    let emojiHeight = 15
    const scaleWidth = {x: 100 / projectEmojiData.boxSize[0], y: 100 / projectEmojiData.boxSize[1]};

    function toPxWidth(value, index) {
        let parentWidth = mapRef.current[index].current?.clientWidth || 0;
        return Number(((parseFloat(value) * parseFloat(parentWidth)) / projectEmojiData.maxGridX).toFixed());
    }

    function toPxHeight(value, index) {
        let parentHeight = mapRef.current[index].current?.clientHeight || 0;
        return Number(
            ((parseFloat(value) * parseFloat(parentHeight)) / projectEmojiData.maxGridY).toFixed()
        );
    }

    useEffect(() => {
        if (canvasRenderRef.current === false) {
            canvasRenderRef.current = true;

            const updatedDataChoice = statDataChoice.map((item, index) => {
                let max = 0;

                return {
                    ...item,
                    data: item.parameters.map((params, i) => {
                        let image = imageList[params.key];
                        let points = params.value.split(',');
                        max = Math.max(max, params[2]);

                        let offsetX = Math.round(mapRef.current[index].current?.clientWidth / 100 * emojiHeight / 2)
                        let offsetY = Math.round(mapRef.current[index].current?.clientHeight / 100 * emojiHeight / 2)

                        return {
                            x: toPxWidth(points[0], index) - offsetX,
                            y: toPxHeight(points[1], index) - offsetY,
                            image: image
                        };
                    })
                };
            });

            setDataChoice(updatedDataChoice);
        }
    }, []);

    return (
        <StyledContainer>
            <StyledEmojiContainer>
                {dataChoice.map((item, index) => {
                    return (
                        <StyledEmojiItem key={`${index}`}>
                            <StyledEmojiInfo>
                                <StyledEmojiText>
                                    {`Completion  № ${item.completion_id}`}
                                </StyledEmojiText>
                            </StyledEmojiInfo>
                            <StyledEmojiMap
                                ref={mapRef.current[index]}
                                width={width}
                                height={height}
                                background={`url('${mapImage}')`}
                                alt={mapName}
                                backgroundFlag={!projectEmojiData.backgroundBox?.image}
                                scale={scaleWidth}
                                boxPosition={projectEmojiData.boxPosition}
                                boxSize={projectEmojiData.boxSize}
                            >
                                {statDataChoice[index]?.data?.map((points, i) => {
                                    return (
                                        <StyledEmojiImg
                                            key={`${index}${i}`}
                                            emojiHeight={emojiHeight}
                                            style={{left: points.x + 'px', top: points.y + 'px'}}
                                            src={points.image}
                                        />
                                    )
                                })
                                }
                            </StyledEmojiMap>
                        </StyledEmojiItem>
                    );
                })}
            </StyledEmojiContainer>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
  padding: 20px 10px;
`;
const StyledEmojiContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;
const StyledEmojiItem = styled.div`
  margin: 15px 5px;
`;
const StyledEmojiInfo = styled.div`
  text-align: center;
  padding: 5px 0;
`;
const StyledEmojiText = styled.span`
  padding: 5px 10px;
  color: #7fd7e7;
  border-bottom: 1px black solid;
`;
const StyledEmojiMap = styled.div`
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};
  margin: auto;
  background-image: ${props => props.background};
  background-repeat: no-repeat;
  background-size: contain;
  background-size: ${props => props.backgroundFlag && `${100 * props.scale.x}% ${100 * props.scale.y}%`};
  background-position: center;
  background-position: ${props => props.backgroundFlag &&
    `-${props.width / props.boxSize[0] * props.boxPosition[0]}px
           -${props.height / props.boxSize[1] * props.boxPosition[1]}px`};
  position: relative;
  ${props => props.backgroundFlag && `overflow: hidden;`}
`;
const StyledEmojiImg = styled.img`
  height: ${props => props.emojiHeight}%;
  position: absolute;
`;