import React, {useRef, useEffect} from 'react';
import styled from 'styled-components';
import {InputFileButton} from './InputFileButton';
import TextField from '@mui/material/TextField';
import {Slider} from "./Slider";

export const EmojiOptionBox = (
  {
    id,
    title,
    name,
    onChangeName,
    image,
    onChangeImage,
    posX,
    posY,
    width,
    height,
    onChangeSizePosX,
    onChangeSizePosY,
    onChangeWidth,
    onChangeHeight,
  }) => {
  return (
    <StyledBox>
      <h4>{title}</h4>

      <StyledTextField
        size="small"
        fullWidth
        label="Name"
        inputProps={{maxLength: 20}}
        defaultValue={name}
        onChange={onChangeName}
      />

      <InputFileButton id={id} text="Add Image" value={image} onChange={onChangeImage}/>

      <Slider min={0} max={100} name="Option position x" value={posX} onChangeValue={onChangeSizePosX}/>
      <Slider min={0} max={100} name="Option position y" value={posY} onChangeValue={onChangeSizePosY}/>

      <Slider min={0} max={100} name="Option width" value={width} onChangeValue={onChangeWidth}/>
      <Slider min={0} max={100} name="Option height" value={height} onChangeValue={onChangeHeight}/>

    </StyledBox>
  );
};

const StyledBox = styled.div`
  border: 1px solid #aaa;
  margin: 10px 5px;
  border-radius: 3px;
  user-select: none;

  h4 {
    color: #fff;
    font-weight: normal;
    text-align: center;
    margin: 15px 0;
  }
`;

const StyledTextField = styled(TextField)`
  &.MuiFormControl-root {
    display: block;
    margin: 20px auto 0;
    width: calc(100% - 20px);
  }

  & input {
    color: #fff;
    height: 27px;
  }

  & label {
    color: #fff;
    line-height: 27px;
    z-index: 0;
  }

  & fieldset,
  & fieldset:hover {
    border: 1px solid #fff !important;
  }

  & label.Mui-focused {
    color: #fff;
  }

  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid #fff !important;
    }
  }
`;
