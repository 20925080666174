import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { LinkButton } from '../../UI/LinkButton';
import { ColorPickerButton } from '../../UI/ColorPickerButton';
import { Slider } from '../../UI/Slider';
import { InputFileButton } from '../../UI/InputFileButton';
import { SelectFontFamily } from '../../UI/SelectFontFamily';
import { CounterButtonGroup } from '../../UI/CounterButtonGroup';
import { ItemBox } from '../../UI/ItemBox';
import { SwitchBox } from '../../UI/SwitchBox';
import { getEmojiDefault } from '../Visual/Emoji';
import {EmojiOptionBox} from "../../UI/EmojiOptionBox";

export const EmojiFieldsModule = ({
  number,
  id,
  backgroundColor,
  setBackgroundColor,
  backgroundData,
  setBackgroundData,
  fontFamily,
  setFontFamily,
  textBoxColor,
  setTextBoxColor,
  textBoxFontSize,
  setTextBoxFontSize,
  backgroundBox,
  setBackgroundBox,
  emojiList,
  setEmojiList,
  emojiSize,
  setEmojiSize,
  emojiNameShow,
  setEmojiNameShow,
  emojiNameColor,
  setEmojiNameColor,
  emojiNameFontSize,
  setEmojiNameFontSize,
  removeModule,
  optionNameList,
  setOptionNameList,
  optionPosXList,
  optionPosYList,
  setOptionPosXList,
  setOptionPosYList,
  optionWidthList,
  optionHeightList,
  setOptionWidthList,
  setOptionHeightList,
  optionImageList,
  setOptionImageList,
}) => {
  function addProp(obj, i) {
    const arr = [...emojiList];
    arr[i] = {
      ...emojiList[i],
      ...obj
    };
    setEmojiList(arr);
  }
  return (
    <StyledFields>
      <StyledNumber>{number}</StyledNumber>
      <ColorPickerButton name="Background color" color={backgroundColor} onChangeColor={setBackgroundColor} />
      <InputFileButton id={id} text="Add Background Image" value={backgroundData} onChange={setBackgroundData}/>
      <SelectFontFamily onChange={setFontFamily} value={fontFamily}/>
      <hr/>
      <h3>Text box</h3>
      <ColorPickerButton name="Color" color={textBoxColor} onChangeColor={setTextBoxColor}/>
      <Slider name="Font size" value={textBoxFontSize} onChangeValue={(e) => setTextBoxFontSize(e.target.value)}/>
      <hr />
      <h3>Map Box</h3>
      <InputFileButton id={id} text="Add Map Background" value={backgroundBox} onChange={setBackgroundBox}/>
      <hr />
      <h3>Emoji</h3>
      <CounterButtonGroup min={0} max={6} count={emojiList.length} onChange={(len) => {
        setEmojiList([...Array(len)].map((x, i) => emojiList[i] ? emojiList[i] : getEmojiDefault(i)));
      }}/>

      <Slider min={5} max={30} name="Width" value={emojiSize[0]} onChangeValue={(e) => setEmojiSize([e.target.value, emojiSize[1]])}/>
      <Slider min={5} max={40} name="Height" value={emojiSize[1]} onChangeValue={(e) => setEmojiSize([emojiSize[0], e.target.value])}/>

      {[...Array(emojiList.length)].map((x, i) =>
        <ItemBox
          key={id + i}
          id={id}
          title={`Emoji ${i + 1}`}
          name={emojiList[i].name}
          image={emojiList[i].imageData}
          onChangeName={(e) => addProp({name: e.target.value}, i)}
          onChangeImage={(imageData) => addProp({imageData}, i)}
        />
      )}

      <StyledNames>
        <SwitchBox title="Display Names" checked={emojiNameShow} setChecked={setEmojiNameShow} />
        {
          emojiNameShow && (
            <>
              <ColorPickerButton name="Color" color={emojiNameColor} onChangeColor={setEmojiNameColor} />
              <Slider min={2} max={10} name="Font size" value={emojiNameFontSize} onChangeValue={(e) => setEmojiNameFontSize(e.target.value)}/>
            </>
          )
        }
      </StyledNames>

      <hr />

      <h3>Options</h3>
      <CounterButtonGroup min={0} max={6} count={optionNameList.length} onChange={(len) => {
        setOptionImageList([...Array(len)].map((x, i) => optionImageList[i] ? optionImageList[i] : null))
        setOptionNameList([...Array(len)].map((x, i) => optionNameList[i] ? optionNameList[i] : `Item #${i + 1}`))
        setOptionWidthList([...Array(len)].map((x, i) => optionWidthList[i] ? optionWidthList[i] : 20))
        setOptionHeightList([...Array(len)].map((x, i) => optionHeightList[i] ? optionHeightList[i] : 20))
        setOptionPosXList([...Array(len)].map((x, i) => optionPosXList[i] ? optionPosXList[i] : 0))
        setOptionPosYList([...Array(len)].map((x, i) => optionPosYList[i] ? optionPosYList[i] : 0))
      }}/>

      {[...Array(optionNameList.length)].map((x, i) =>
        <EmojiOptionBox
          key={id + i}
          id={id}
          title={`Option ${i + 1}`}
          name={optionNameList[i] || `Item #${i + 1}`}
          onChangeName={(e) => {
            const arr = [...optionNameList];
            arr[i] = e.target.value;
            setOptionNameList(arr);
          }}
          image={optionImageList[i]}
          onChangeImage={(img) => {
            const arr = [...optionImageList];
            arr[i] = img;
            setOptionImageList(arr);
          }}
          posX={optionPosXList[i]}
          posY={optionPosYList[i]}
          width={optionWidthList[i]}
          height={optionHeightList[i]}
          onChangeSizePosX={(e) => {
            const arr = [...optionPosXList];
            arr[i] = e.target.value;
            setOptionPosXList(arr);
          }}
          onChangeSizePosY={(e) => {
            const arr = [...optionPosYList];
            arr[i] = e.target.value;
            setOptionPosYList(arr);
          }}
          onChangeWidth={(e) => {
            const arr = [...optionWidthList];
            arr[i] = e.target.value;
            setOptionWidthList(arr);
          }}
          onChangeHeight={(e) => {
            const arr = [...optionHeightList];
            arr[i] = e.target.value;
            setOptionHeightList(arr);
          }}
        />
      )}

      <hr/>

      <LinkButton text="Delete this module" onClick={removeModule} />
    </StyledFields>
  );
};

const StyledFields = styled.div`
  user-select: none;
  padding: 5px;
  h3 {
    color: #fff;
    font-weight: normal;
    text-align: center;
    margin: 0;
  }
`;

const StyledNumber = styled.div`
  border: 1px solid #aaa;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
`;

const StyledNames = styled.div`
  margin: 10px 5px;
  border: 1px solid #aaa;
  border-radius: 3px;
  & > * {
    margin: 20px auto 5px;
    width: 80%;
  }
`;
