import React, {useState, useEffect} from 'react';
import {Button, ButtonGroup} from '@mui/material';
import styled from 'styled-components';

export const CounterButtonGroup = ({count, onChange, min = 3, max = 6}) => {
  return (
    <StyledContainer>
      <ButtonGroup size="small" variant="contained" aria-label="small button group" style={{width: "100%"}}>
        <Button disabled={count <= min} onClick={() => onChange(count - 1)}>-</Button>
        <Button disabled style={{color: '#fff'}}>{count}</Button>
        <Button disabled={count >= max} onClick={() => onChange(count + 1)}>+</Button>
      </ButtonGroup>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
  margin: 10px auto;
`;
