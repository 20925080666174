import React, {useRef, useEffect} from 'react';
import styled from 'styled-components';
import {ColorPickerButton} from '../UI/ColorPickerButton';
import {Slider} from '../UI/Slider';

export const TextBox = ({onChange, data, width, height}) => {
  return (
    <>
      <ColorPickerButton name="Background color" color={data.background} onChangeColor={(e) => onChange({background: e})}/>
      <ColorPickerButton name="Font color" color={data.color} onChangeColor={(e) => onChange({color: e})}/>
      <Slider min={3} max={13} name="Font size" value={data.fontSize} onChangeValue={(e) => onChange({fontSize: e.target.value})}/>
      {width && <Slider min={5} max={50} name="Width" value={data.width} onChangeValue={(e) => onChange({width: e.target.value})}/>}
      {height && <Slider min={5} max={50} name="Height" value={data.height} onChangeValue={(e) => onChange({height: e.target.value})}/>}
    </>
  );
};
