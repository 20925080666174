import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {ColorPickerButton} from '../../UI/ColorPickerButton';
import {SelectFontFamily} from '../../UI/SelectFontFamily';
import {Slider} from '../../UI/Slider';
import {InputFileButton} from '../../UI/InputFileButton';
import {LinkButton} from '../../UI/LinkButton';
import {ItemBox} from '../../UI/ItemBox';
import {CounterButtonGroup} from '../../UI/CounterButtonGroup';
import {SliderOptionBox} from "../../UI/SliderOptionBox";

export const SliderFieldsModule = ({
  number,
  id,
  textBoxFontSize,
  setTextBoxFontSize,
  fontFamily,
  setFontFamily,
  textBoxColor,
  setTextBoxColor,
  setBackgroundData,
  backgroundData,
  backgroundColor,
  setBackgroundColor,
  optionNameList,
  optionImageList,
  sliderImageList,
  setSliderImageList,
  sliderNameList,
  setSliderNameList,
  setOptionNameList,
  setOptionImageList,
  optionSizeList,
  setOptionSizeList,
  sliderLineColor,
  setSliderLineColor,
  sliderImageSize,
  setSliderImageSize,
  removeModule
}) => {
  return (
    <StyledFields>
      <StyledNumber>{number}</StyledNumber>
      <ColorPickerButton name="Background color" color={backgroundColor} onChangeColor={setBackgroundColor}/>
      <InputFileButton id={id} text="Add Background Image" value={backgroundData} onChange={setBackgroundData}/>
      <SelectFontFamily onChange={setFontFamily} value={fontFamily}/>
      <hr/>
      <h3>Text box</h3>
      <ColorPickerButton name="Color" color={textBoxColor} onChangeColor={setTextBoxColor}/>
      <Slider name="Font size" value={textBoxFontSize} onChangeValue={(e) => setTextBoxFontSize(e.target.value)}/>
      <hr/>
      <h3>Slider settings</h3>
      <ColorPickerButton name="Slider line color" color={sliderLineColor} onChangeColor={setSliderLineColor}/>
      <Slider max={8} name="Image size" value={sliderImageSize} onChangeValue={(e) => setSliderImageSize(e.target.value)}/>
      <hr/>
      <h3>Sliders</h3>
      <CounterButtonGroup min={1} max={6} count={sliderImageList.length} onChange={(len) => {
        setSliderImageList([...Array(len)].map((x, i) => sliderImageList[i] ? sliderImageList[i] : null))
        setSliderNameList([...Array(len)].map((x, i) => sliderNameList[i] ? sliderNameList[i] : `Item #${i + 1}`))
      }}/>

      {[...Array(sliderImageList.length)].map((x, i) =>
        <ItemBox
          key={id + i}
          id={id}
          title={`Slider ${i + 1}`}
          name={sliderNameList[i] || `Item #${i + 1}`}
          onChangeName={(e) => {
            const arr = [...sliderNameList];
            arr[i] = e.target.value;
            setSliderNameList(arr);
          }}
          image={sliderImageList[i]}
          onChangeImage={(img) => {
            const arr = [...sliderImageList];
            arr[i] = img;
            setSliderImageList(arr);
          }}
        />
      )}
      <hr/>
      <h3>Options</h3>
      <CounterButtonGroup min={0} max={6} count={optionNameList.length} onChange={(len) => {
        setOptionImageList([...Array(len)].map((x, i) => optionImageList[i] ? optionImageList[i] : null))
        setOptionNameList([...Array(len)].map((x, i) => optionNameList[i] ? optionNameList[i] : `Item #${i + 1}`))
        setOptionSizeList([...Array(len)].map((x, i) => 100 / len))
      }}/>

      {[...Array(optionNameList.length)].map((x, i) =>
        <SliderOptionBox
          key={id + i}
          id={id}
          title={`Option ${i + 1}`}
          name={optionNameList[i] || `Item #${i + 1}`}
          onChangeName={(e) => {
            const arr = [...optionNameList];
            arr[i] = e.target.value;
            setOptionNameList(arr);
          }}
          image={optionImageList[i]}
          onChangeImage={(img) => {
            const arr = [...optionImageList];
            arr[i] = img;
            setOptionImageList(arr);
          }}
          size={optionSizeList[i]}
          onChangeSize={(e) => {
            const arr = [...optionSizeList];
            arr[i] = e.target.value;
            const sizeSum = arr.reduce((partialSum, a) => partialSum + a, 0);
            if (sizeSum > 100) {
              arr[i] = e.target.value - (sizeSum - 100);
            }
            setOptionSizeList(arr);
          }}
        />
      )}
      <hr/>
      <LinkButton text="Delete this module" onClick={removeModule}/>
    </StyledFields>
  );
};

const StyledFields = styled.div`
  user-select: none;
  padding: 5px;
  h3 {
    color: #fff;
    font-weight: normal;
    text-align: center;
    margin: 0;
  }
`;

const StyledNumber = styled.div`
  border: 1px solid #aaa;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
`;
