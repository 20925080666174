import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import Service from '../API/service';
import {useFetching} from '../hooks/useFetching';
import {Loader} from '../components/UI/Loader';
import {TextDetail} from "../components/ModuleList/PublicReport/TextDetail";

export const ClientReport = () => {
  const {projectId} = useParams();
  const syncRef = useRef(false);
  const [statDataChoice, setDataChoice] = useState(null);
  const [statProject, setProject] = useState(null);

  const [loadData, isLoading, error] = useFetching(async () => {
    const project = await Service.getProject(projectId);
    const parameters = await Service.getParametersTextByProject(projectId);

    setProject(project.data)
    setDataChoice(parameters.data)
  });

  useEffect(() => {
    if (syncRef.current === false) {
      syncRef.current = true;
      loadData();
    }
  }, []);

  return (
    <StyledStat>
      {isLoading ? (
        <Loader/>
      ) : error ? (
        <h1>Statistics not found</h1>
      ) : (
        statDataChoice && (
          <div>
            <StyledContent>
              <header>
                <StyledColorHeader>
                </StyledColorHeader>
                <StyledInfoHeader>
                  <StyledInfoHeaderName>
                    <h2>{statProject.name}</h2>
                    <p>{statProject.created_at}</p>
                  </StyledInfoHeaderName>
                  <StyledInfoHeaderCount>
                    <h2>Responses</h2>
                    <p>{statDataChoice.count}</p>
                  </StyledInfoHeaderCount>
                </StyledInfoHeader>
              </header>
              <StyledMainContent>
                <h2>Qualitative answers</h2>
                <div>
                  <div>
                    {
                      Object.entries(statDataChoice.data).map(([key, val]) => {
                        return (<TextDetail key={key} dataChoice={val}></TextDetail>)
                      })
                    }
                  </div>
                </div>
              </StyledMainContent>
            </StyledContent>
          </div>
        )
      )}
    </StyledStat>
  );
};

const StyledStat = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
`;

const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const StyledColorHeader = styled.div`
  height: 40px;
  background-color: #0072c2;
`;

const StyledInfoHeader = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px 20px;
  background: #e5e5e5;
`;

const StyledInfoHeaderName = styled.div`
`;

const StyledInfoHeaderCount = styled.div`
`;

const StyledMainContent = styled.main`
  max-width: 1080px;
  margin: 0 auto;
`;
