import React from 'react';
import {SelectCustom} from '../UI/SelectCustom';

export const SelectFontFamily = ({value, onChange}) => {
  return (
    <SelectCustom
      title="Font family"
      list={['Alegreya-Regular', 'JosefinSans-Regular', 'Poppins-Regular', 'RobotoSlab-Regular']}
      value={value}
      onChange={onChange}
    />
  );
};
