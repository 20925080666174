import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Left} from './Left';

export const PageFrame = (props) => {
  return (
    <>
      <Left/>
      {
        props.header && <StyledHeader>{props.header}</StyledHeader>
      }
      <StyledBody>{props.children}</StyledBody>
    </>
  );
};

const StyledHeader = styled.div`
  margin-left: 85px;
`;

const StyledBody = styled.div`
  margin-left: 85px;
  display: flex;
  height: calc(100vh - 110px);
`;
