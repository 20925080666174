import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {LinkButton} from '../../UI/LinkButton';
import {ColorPickerButton} from '../../UI/ColorPickerButton';
import {SelectFontFamily} from '../../UI/SelectFontFamily';
import {SelectCustom} from '../../UI/SelectCustom';
import {TextBox} from '../../UI/TextBox';
import {ItemBox} from '../../UI/ItemBox';
import {CounterButtonGroup} from '../../UI/CounterButtonGroup';

export const PhotoFieldsModule = ({
  number,
  id,
  backgroundColor,
  setBackgroundColor,
  fontFamily,
  setFontFamily,
  sliderColor,
  setSliderColor,
  topPhotoList,
  setTopPhotoList,
  topNameList,
  setTopNameList,
  bottomPhotoList,
  setBottomPhotoList,
  bottomNameList,
  setBottomNameList,
  topTextBox,
  setTopTextBox,
  bottomTextBox,
  setBottomTextBox,
  middleTextBox,
  setMiddleTextBox,
  removeModule
}) => {
  return (
    <StyledFields>
      <StyledNumber>{number}</StyledNumber>
      <ColorPickerButton name="Background color" color={backgroundColor} onChangeColor={setBackgroundColor}/>
      <SelectFontFamily onChange={setFontFamily} value={fontFamily}/>
      <ColorPickerButton name="Slider color" color={sliderColor} onChangeColor={setSliderColor} format="hex"/>
      <hr/>

      <h3>Top photo</h3>
      <CounterButtonGroup min={2} max={3} count={topPhotoList.length} onChange={(len) => {
        setTopPhotoList([...Array(len)].map((x, i) => topPhotoList[i] ? topPhotoList[i] : null))
        setTopNameList([...Array(len)].map((x, i) => topNameList[i] ? topNameList[i] : `Item #${i + 1}`))
      }}/>

      {[...Array(topPhotoList.length)].map((x, i) =>
        <ItemBox
          key={`${id}_top_${i}`}
          id={id}
          title={`Photo ${i + 1}`}
          name={topNameList[i] || `Item #${i + 1}`}
          onChangeName={(e) => {
            const arr = [...topNameList];
            arr[i] = e.target.value;
            setTopNameList(arr);
          }}
          image={topPhotoList[i]}
          onChangeImage={(img) => {
            const arr = [...topPhotoList];
            arr[i] = img;
            setTopPhotoList(arr);
          }}
        />
      )}
      <hr/>

      <h3>Bottom photo</h3>
      <CounterButtonGroup min={2} max={3} count={bottomPhotoList.length} onChange={(len) => {
        setBottomPhotoList([...Array(len)].map((x, i) => bottomPhotoList[i] ? bottomPhotoList[i] : null))
        setBottomNameList([...Array(len)].map((x, i) => bottomNameList[i] ? bottomNameList[i] : `Item #${i + 1}`))
      }}/>

      {[...Array(bottomPhotoList.length)].map((x, i) =>
        <ItemBox
          key={`${id}_bottom_${i}`}
          id={id}
          title={`Photo ${i + 1}`}
          name={bottomNameList[i] || `Item #${i + 1}`}
          onChangeName={(e) => {
            const arr = [...bottomNameList];
            arr[i] = e.target.value;
            setBottomNameList(arr);
          }}
          image={bottomPhotoList[i]}
          onChangeImage={(img) => {
            const arr = [...bottomPhotoList];
            arr[i] = img;
            setBottomPhotoList(arr);
          }}
        />
      )}
      <hr/>

      <h3>Top text box</h3>
      <SelectCustom title="Position" list={['none', 'left', 'right']} value={topTextBox.position}
        onChange={(e) => setTopTextBox({...topTextBox, position: e})}/>
      {
        topTextBox.position !== 'none' &&
          <TextBox width={true} data={topTextBox} onChange={(obj) => setTopTextBox({...topTextBox, ...obj})}/>
      }
      <hr/>

      <h3>Bottom text box</h3>
      <SelectCustom title="Position" list={['none', 'left', 'right']} value={bottomTextBox.position}
        onChange={(e) => setBottomTextBox({...bottomTextBox, position: e})}/>
      {
        bottomTextBox.position !== 'none' &&
          <TextBox width={true} data={bottomTextBox} onChange={(obj) => setBottomTextBox({...bottomTextBox, ...obj})}/>
      }
      <hr/>
      <h3>Middle text box</h3>
      <SelectCustom title="Position" list={['none', 'cover', 'inside']} value={middleTextBox.position}
        onChange={(e) => setMiddleTextBox({...middleTextBox, position: e})}/>
      {
        middleTextBox.position !== 'none' &&
          <TextBox width={middleTextBox.position === 'cover' ? true : false} height={true} data={middleTextBox} onChange={(obj) => setMiddleTextBox({...middleTextBox, ...obj})}/>
      }
      <hr/>

      <LinkButton text="Delete this module" onClick={removeModule}/>
    </StyledFields>
  );
};

const StyledFields = styled.div`
  user-select: none;
  padding: 5px;
  h3 {
    color: #fff;
    font-weight: normal;
    text-align: center;
    margin: 0;
  }
`;

const StyledNumber = styled.div`
  border: 1px solid #aaa;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
`;
