import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import nextAnimation from '../../assets/images/icons/lottle/animation_next.json';
import Lottie from "lottie-react";

export const NextButton = ({onClick}) => {
  return (
    <StyledButton
      onClick={onClick}
    >
      <Lottie animationData={nextAnimation} loop={false}/>
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  position: absolute !important;
  right: 3% !important;
  margin: auto !important;
  bottom: 0 !important;
  top: 0 !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  min-width: auto !important;
  z-index: 1;
`;
