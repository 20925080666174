import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import Service from '../../API/service';
import Button from '@mui/material/Button';
import { useFetching } from '../../hooks/useFetching';
import { useNavigate } from 'react-router-dom';

export const HeaderReport = ({ id }) => {
  const navigate = useNavigate();
  const [copyProject] = useFetching(async () => {
    const response = await Service.copyProject(id);
    if (response) {
      navigate(`/projects`);
    }
  });
  const [createAndEditProject] = useFetching(async () => {
    let url = Math.random().toString(16).slice(2);

    const response = await Service.setProject({
      color: '#eee',
      name: 'Survey',
      last_link: '',
      url: url,
      is_published: false,
      moduleList: []
    });

    if (response) {
      navigate(`/project-editor/${url}`);
    }
  });

  return (
    <StyledHeader>
      <StyledTitle className='title'>Project report</StyledTitle>
      <div className='buttons'>
        <Button
            className='button'
            variant='contained'
            component={RouterLink}
            to={`/project-editor/${id}`}>
          EDIT
        </Button>
        <Button
            className='button'
            variant='contained'
            component={RouterLink}
            to={`/client-report/${id}`}>
          Public
        </Button>
        <Button
            className='button'
            variant='contained'
            onClick={copyProject}>
          CLONE
        </Button>
        <Button
            className='button'
            variant='contained'
            href={`https://api.${window.location.host}/api/report/${id}`}>
          Report
        </Button>
        <Button
          className='button'
          variant='contained'
          component={RouterLink}
          to={`/project/${id}?sync_off=1`}>
          PREVIEW
        </Button>
        <Button className='button' variant='contained' onClick={createAndEditProject}>
          CREATE NEW
        </Button>
      </div>
    </StyledHeader>
  );
};
const IconButtonImage = styled.img`
  width: 30px;
  height: 30px;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 110px;
  background: #42464c;
  color: #fff;
  .buttons {
    display: flex;
    justify-content: space-evenly;
    padding: 0 10px;
  }
  .button {
    margin: 5px;
  }
`;

const StyledTitle = styled.div`
  width: 100%;
  text-transform: capitalize;
  padding: 15px;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  display: inline;
  line-height: 24px;
  margin: 0 auto;
  @media (min-width: 950px) {
    font-size: 32px;
    line-height: 46px;
  }
`;
