import React, {useState, useEffect, useDeferredValue} from 'react';
import styled from 'styled-components';
import {SketchPicker} from 'react-color';

export const ColorPicker = ({defaultColor, onSetColor, format}) => {
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(defaultColor);
  const defferedColor = useDeferredValue(color);

  function handleClick() {
    setStatus(!status);
  }

  function handleClose() {
    setStatus(false);
  }

  function handleChange(color) {
    if (format) {
      setColor(color[format]);
    } else {
      color = color.rgb;
      let rgba = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
      setColor(rgba);
    }
  }

  useEffect(() => {
    onSetColor(defferedColor);
  }, [defferedColor]);

  return (
    <StyledContainer>
      <StyledSwatch onClick={handleClick}>
        <StyledColor color={color} />
      </StyledSwatch>
      {status ? (
        <StyledPopover>
          <StyledCover onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} />
        </StyledPopover>
      ) : null}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: relative;
`;

const StyledSwatch = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledColor = styled.div`
  width: 36px;
  height: 14px;
  border-radius: 2px;
  background: ${props => props.color};
`;

const StyledPopover = styled.div`
  position: absolute;
  z-index: 2;
`;

const StyledCover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
