import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';

export const Header = ({onSaveModal, saveOn = true, onDeleteModal, onPreview}) => {
  return (
    <StyledHeader>
      <StyledTitle className="title">Edit/Create Survey Modules</StyledTitle>
      <div className="buttons">
        <Button onClick={() => onDeleteModal(true)} className="button" variant="contained">
          DELETE
        </Button>
        <Button onClick={onPreview} className="button" variant="contained">
          PREVIEW
        </Button>
        <Button onClick={() => onSaveModal(true)} disabled={saveOn ? false : true} className="button" variant="contained">
          SAVE
        </Button>
      </div>
    </StyledHeader>
  );
}

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 110px;
  background: #42464c;
  color: #fff;
  .buttons {
    display: flex;
    justify-content: space-evenly;
    padding: 0 10px;
  }
  .button {
    margin: 5px;
  }
`;

const StyledTitle = styled.div`
  width: 100%;
  text-transform: capitalize;
  padding: 15px;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  display: inline;
  line-height: 24px;
  margin: 0 auto;
  @media (min-width: 950px) {
    font-size: 32px;
    line-height: 46px;
  }
`;

