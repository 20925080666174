import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import {IconButton} from "@mui/material";
import {CloseSharp} from "@mui/icons-material";
import Service from "../../../API/service";
import {AuthContext} from "../../../context";

export const TextDetail = ({dataChoice}) => {
  const {isAuth} = useContext(AuthContext);
  const [statParameters, setParameters] = useState(dataChoice.parameters);

  async function setPublicParameter(choiceId) {
    const response = await Service.setPublicChoice(choiceId);
    if (response.status === 204) {
      setParameters(prevParameters => prevParameters.filter(param => param.id !== choiceId));
    }
  }

  return (
    <StyledContainer>
      <h2>{dataChoice.question.replace(/<[^>]+>/g, '')}</h2>
      {statParameters.length !== 0 ? statParameters.map((choice, index) => {
        return (
          <StyledTextItem key={`${choice.id}`}>
            <div>
              <StyledRespondent>{`Completion № ${choice.completion_id}`}
              </StyledRespondent>{`${choice.text}`}
            </div>
            {isAuth &&
              <IconButton onClick={() => setPublicParameter(choice.id)}
                          sx={{color: '#050505', width: '20px', height: '20px'}}>
                <CloseSharp sx={{fontSize: 22}}/>
              </IconButton>
            }
          </StyledTextItem>
        );
      }) : 'No answers'}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  padding: 20px 10px;
`;

const StyledTextItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;
  padding: 10px;
  border-bottom: 1px black solid;
`;
const StyledRespondent = styled.span`
  color: #7fd7e7;
  padding-right: 15px;
`;
