import {createTheme} from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#53A451'
    },
    tonalOffset: 0.05,
    action: {
      disabledBackground: false
    }
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: {},
          style: {
            width: '131px',
            height: '33px',
            fontSize: '14px',
            padding: '0'
          }
        },
        {
          props: { size: 'large' },
          style: {
            width: '230px',
            height: '58px',
            fontSize: '25px'
          }
        }
      ]
    }
  }
});
