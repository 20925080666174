import React, {useRef, useEffect} from 'react';
import styled from 'styled-components';
import {InputFileButton} from '../UI/InputFileButton';
import TextField from '@mui/material/TextField';

export const ItemBox = ({id, title, name, onChangeName, image, onChangeImage}) => {
  return (
    <StyledBox>
      <h4>{title}</h4>

      <StyledTextField
        size="small"
        fullWidth
        label="Name"
        inputProps={{ maxLength: 20 }}
        defaultValue={name}
        onChange={onChangeName}
      />
      <InputFileButton id={id} text="Add Image" value={image} onChange={onChangeImage}/>
    </StyledBox>
  );
};

const StyledBox = styled.div`
  border: 1px solid #aaa;
  margin: 10px 5px;
  border-radius: 3px;
  user-select: none;
  h4 {
    color: #fff;
    font-weight: normal;
    text-align: center;
    margin: 15px 0;
  }
`;

const StyledTextField = styled(TextField)`
  &.MuiFormControl-root {
    display: block;
    margin: 20px auto 0;
    width: calc(100% - 20px);
  }
  & input {
    color: #fff;
    height: 27px;
  }
  & label {
    color: #fff;
    line-height: 27px;
    z-index: 0;
  }
  & fieldset,
  & fieldset:hover {
    border: 1px solid #fff !important;
  }
  & label.Mui-focused {
    color: #fff;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid #fff !important;
    }
  }
`;
