import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {ModuleFrame} from '../../parts/ModuleFrame';

export const TextModule = ({number, id, data, onNext, previewMode = false}) => {
  const [moduleWidthCoef, setModuleWidthCoef] = useState(0);
  const [textData, setTextData] = useState({text: ''});
  const inputRef = useRef(null);

  function resetTextData() {
    setTextData((prevTextData) => ({
      ...prevTextData,
      text: ''
    }));
  }

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    resetTextData();
  }, [number]);

  function sendData() {
    if (!previewMode) {
      onNext({
        name: 'Text',
        id: id,
        data: textData
      });
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }

  return (
    <ModuleFrame fontFamily={data.fontFamily} backgroundImage={data.backgroundData}
                 onChangeModuleWidthCoef={(value) => setModuleWidthCoef(value)}>
      <StyledText
        position={data.textBoxPosition}
        sizes={data.textBoxSize}
        font={data.textBoxFontSize * moduleWidthCoef}
        color={data.textBoxColor}
        dangerouslySetInnerHTML={{__html: data.boxText}}
      ></StyledText>

      <StyledInput
        ref={inputRef}
        value={textData.text}
        position={data.inputBoxPosition}
        sizes={data.inputBoxSize}
        font={data.inputBoxFontSize * moduleWidthCoef}
        color={data.inputBoxColor}
        onChange={(e) => setTextData({text: e.target.value})}
      ></StyledInput>

      <StyledButton
        position={data.buttonPosition}
        sizes={data.buttonSize}
        font={data.buttonFontSize * moduleWidthCoef}
        textColor={data.buttonTextColor}
        radius={data.buttonRadius}
        color={data.buttonColor}
        onClick={() => sendData()}
        dangerouslySetInnerHTML={{__html: data.buttonText}}
      ></StyledButton>

    </ModuleFrame>
  );
};

const StyledText = styled.div`
  width: ${props => props.sizes[0] + '%'};
  height: ${props => props.sizes[1] + '%'};
  position: absolute;
  top: ${props => props.position[1] + '%'};
  left: ${props => props.position[0] + '%'};
  text-align: center;
  font-size: ${props => 0.9 * props.font + 'vw'} !important;
  line-height: 1.2;
  overflow: hidden;
  color: ${props => props.color};
`;

const StyledInput = styled.textarea`
  width: ${props => props.sizes[0] + '%'};
  height: ${props => props.sizes[1] + '%'};
  position: absolute;
  top: ${props => props.position[1] + '%'};
  left: ${props => props.position[0] + '%'};
  font-size: ${props => 0.9 * props.font + 'vw'} !important;
  line-height: 1.2;
  overflow: hidden;
  color: ${props => props.color};
  border: 1px black solid;
  background: transparent;
`;

const StyledButton = styled.div`
  cursor: pointer;
  user-select: none;
  width: ${props => props.sizes[0] + '%'};
  height: ${props => props.sizes[1] + '%'};
  background: ${props => props.color};
  color: ${props => props.textColor};
  position: absolute;
  top: ${props => props.position[1] + '%'};
  left: ${props => props.position[0] + '%'};
  border: 3px solid ${props => props.textColor};
  border-radius: ${props => props.radius + 'px'};
  font-size: ${props => 0.5 * props.font + 'vw'} !important;
  line-height: 1.2;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:focus-visible {
    outline: none;
  }
`;
