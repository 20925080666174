import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import Service from '../API/service';
import {useFetching} from '../hooks/useFetching';
import {Loader} from '../components/UI/Loader';
import {LandscapeModal} from '../components/LandscapeModal';
import {TitleModule} from '../components/ModuleList/Page/Title';
import {TextModule} from "../components/ModuleList/Page/Text";
import {SliderModule} from '../components/ModuleList/Page/Slider';
import {PhotoModule} from '../components/ModuleList/Page/Photo';
import {EmojiModule} from '../components/ModuleList/Page/Emoji';

const pageModules = {
  Title: TitleModule,
  Text: TextModule,
  Slider: SliderModule,
  Photo: PhotoModule,
  Emoji: EmojiModule,
  Closing: TitleModule
};

export const Project = () => {
  const { projectId } = useParams();
  const [moduleList, setModuleList] = useState([]);
  const [lastLink, setLastLink] = useState('');
  const [moduleData, setModuleData] = useState(null);
  const [moduleNumber, setModuleNumber] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState('#fff');
  const [statistics, setStatistics] = useState([]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const noStat = searchParams.get('sync_off') === '1' ? true : false;
  const [warningNoStat, setWarningNoStat] = useState(noStat)
  const syncRef = useRef(false);
  const timerRef = useRef(0);
  const time = 1000 * 60 * 5;

  const [loadProject, isLoading, error] = useFetching(async () => {
    const response = await Service.getProject(projectId);
    document.title = response.data.name;
    setLastLink(response.data.last_link);
    setModuleList(response.data.moduleList);
    setModuleNumber(0);
  });

  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches;
  const [landscapeWarn, setLandscapeWarn] = useState(false);
  let resizeLagTimer, warnTimer;
  const onWindowResize = () => {
    clearTimeout(resizeLagTimer);
    clearTimeout(warnTimer);
    resizeLagTimer = setTimeout(() => {
      let isMobile = window.innerWidth < 1001 ? true : false;
      let orientation = isLandscape() ? 'landscape' : 'portrait';

      if (isMobile && orientation === 'portrait') {
        setLandscapeWarn(true);
        warnTimer = setTimeout(() => {
          setLandscapeWarn(false);
        }, 3000);
      } else {
        setLandscapeWarn(false);
      }
    }, 200);
  };

  useEffect(() => {
    onWindowResize();
    window.addEventListener('resize', onWindowResize);
    return function () {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  function getTimeout() {
    return setTimeout(() => {
      window.location.reload();
    }, time)
  }
  function next(data) {
    if (data) {
      setStatistics([...statistics, data]);
    }
    clearTimeout(timerRef.current);
    timerRef.current = getTimeout()
    setModuleNumber(moduleNumber + 1);
  }

  function redirect() {
    if (lastLink) {
      document.location.href = lastLink;
    } else if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate('/');
    }
  }

  useEffect(() => {
    clearTimeout(timerRef.current);
    timerRef.current = getTimeout()
  }, []);

  useEffect(() => {
    if (moduleList.length === 0) {
      return;
    }

    const moduleData = moduleList[moduleNumber];

    if (moduleData) {
      const Module = pageModules[moduleData.name];

      setModuleData(<Module id={moduleData.id} number={moduleNumber} data={moduleData.data} onNext={next} />);

      setBackgroundColor(moduleData.data.backgroundColor);
    } else {
      if (noStat === false) {
        async function getH() {
          const s = await Service.setStatisticsByProject(projectId, { statistics: statistics });
          redirect();
        }

        getH();
      } else {
        redirect();
      }
    }
  }, [moduleNumber, moduleList]);

  useEffect(() => {
    if (syncRef.current === false) {
      syncRef.current = true;

      loadProject();

      if (noStat === false) {
        Service.setStatisticsByProject(projectId, { pageviews: 1 });
      }
    }
  }, []);

  return (
    <StyledProject backgroundColor={backgroundColor}>
      {error && <h1>Project not found</h1>}
      {!isLoading && warningNoStat &&
        <StyledWarningWindow>
          This survey is in demo mode for testing purposes. Contact your Tallymade rep for the link.
        </StyledWarningWindow>
      }
      {isLoading ? <Loader /> : moduleData && moduleData}
      {landscapeWarn && <LandscapeModal />}
    </StyledProject>
  );
};

const StyledProject = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: ${props => props.backgroundColor ? props.backgroundColor : '#fff'};
`;

const StyledWarningWindow = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 25px;
  left: 50%;
  font-size: 20px;
  z-index: 9999;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 5%;
  color: white;
  padding: 5px 0;
  background-color: deeppink;
  border: 1px solid #c125ff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;
