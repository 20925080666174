import React, {useEffect, useRef, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import {NextButton} from '../../UI/NextButton';
import {ModuleFrame} from '../../parts/ModuleFrame';
import cursor from '../../../assets/images/cursor.png';
import interact from 'interactjs';


export const EmojiModule = ({ number, id, data, onNext, previewMode = false }) => {
  const [nextButtonShow, setNextButtonShow] = useState(false);
  const [moduleWidthCoef, setModuleWidthCoef] = useState(0);
  const [previewCursor, setPreviewCursor] = useState(true)
  const [moveCursorX, setMoveCursorX] = useState(0);
  const [moveCursorY, setMoveCursorY] = useState(0);
  const renderRef = useRef(false);

  function toPercentWidth(value) {
    const parentWidth = document.querySelector('.container')?.clientWidth || 0;
    return (100 * parseFloat(value)) / parseFloat(parentWidth);
  }

  function toPercentHeight(value) {
    const parentHeight = document.querySelector('.container')?.clientHeight || 0;
    return (100 * parseFloat(value)) / parseFloat(parentHeight);
  }

  function sendData(e) {
    let emojiData = [...Array(data.emojiList.length)].map((x, i) => []);

    data.emojiList.map((item, i) => {
      Array.from(document.getElementsByClassName('can-drop'))
        .filter(item => Number(item.getAttribute('data-count')) === i)
        .map(item => {
          const x = Number(item.getAttribute('data-position-x'));
          const y = Number(item.getAttribute('data-position-y'));
          if (!emojiData[i].find(uniqArr => uniqArr[0] === x && uniqArr[1] === y)) {
            // emojiData[i].push([x, y]);
            emojiData[i].push({
              text: data.emojiList[i].name,
              value: [x, y],
            });
          }
        });
    });

    setPreviewCursor(true)
    onNext({
      name: 'Emoji',
      id: id,
      data: emojiData
    });
  }

  useEffect(() => {
    const container = document.querySelector('.container');
    const dropzone = document.querySelector('.dropzone');
    const containerPositions = container.getBoundingClientRect();
    const dropzonePositions = dropzone.getBoundingClientRect();

    setMoveCursorX(dropzonePositions.x + (dropzonePositions.width / 2) - 79 - (containerPositions.width / 100 * data.emojiList[0].position[0]))
    setMoveCursorY(dropzonePositions.y + (dropzonePositions.height / 2) - 158 - (containerPositions.height / 100 * data.emojiList[0].position[1]))

    Array.from(document.getElementsByClassName('can-drop')).map(item => item.remove());
    setNextButtonShow(false);

    if (renderRef.current === true) {
      return;
    }

    renderRef.current = true;

    interact('.dropzone').dropzone({
      overlap: 0.3,
      ondropactivate: function (event) {
        event.target.classList.add('drop-active');
      },
      ondragenter: function (event) {
        const draggable = event.relatedTarget;
        const dropzone = event.target;

        dropzone.classList.add('drop-target');
        draggable.classList.add('can-drop');
      },
      ondragleave: function (event) {
        event.target.classList.remove('drop-target');
        event.relatedTarget.classList.remove('can-drop');
      },
      ondrop: function (event) {},
      ondropdeactivate: function (event) {
        const draggable = event.relatedTarget;
        const dropzone = event.target;
        const maxGridX = Number(dropzone.getAttribute('data-max-x'));
        const maxGridY = Number(dropzone.getAttribute('data-max-y'));

        if (draggable.classList.contains('can-drop')) {
          setNextButtonShow(true);

          let x = draggable.offsetLeft + draggable.offsetWidth / 2 - dropzone.offsetLeft;
          let y = draggable.offsetTop + draggable.offsetHeight / 2 - dropzone.offsetTop;
          let zoneW = dropzone.clientWidth;
          let zoneH = dropzone.clientHeight;

          x = Math.round((x * maxGridX) / zoneW);
          y = Math.round((y * maxGridY) / zoneH);

          if (x < 0) {
            x = 0;
          }
          if (x > maxGridX) {
            x = maxGridX;
          }

          if (y < 0) {
            y = 0;
          }
          if (y > maxGridY) {
            y = maxGridY;
          }

          draggable.setAttribute('data-position-x', x);
          draggable.setAttribute('data-position-y', y);
        } else {
          draggable.remove();
        }

        dropzone.classList.remove('drop-active');
        dropzone.classList.remove('drop-target');
      }
    });

    interact('.drag-drop')
      .draggable({
        listeners: {
          move: event => {
            setPreviewCursor(false)
            const target = event.target;

            const x = toPercentWidth(event.rect.left - containerPositions.left);
            const y = toPercentHeight(event.rect.top - containerPositions.top);

            target.style.left = x + '%';
            target.style.top = y + '%';
          }
        }
      })
      .on('move', function (event) {
        const { currentTarget, interaction } = event;

        if (
          interaction.pointerIsDown &&
          !interaction.interacting() &&
          currentTarget.getAttribute('data-clonable') !== 'false'
        ) {
          const container = document.querySelector('.container');
          const element = currentTarget.cloneNode(true);

          const containerPositions = container.getBoundingClientRect();
          const currentPositions = currentTarget.getBoundingClientRect();

          const x = toPercentWidth(currentPositions.left - containerPositions.left);
          const y = toPercentHeight(currentPositions.top - containerPositions.top);

          element.style.position = 'absolute';
          element.style.left = x + '%';
          element.style.top = y + '%';
          element.style.height = element.getAttribute('data-height');
          element.setAttribute('data-clonable', 'false');

          container.appendChild(element);
          interaction.start({ name: 'drag' }, event.interactable, element);
        }
      });
  }, [id]);

  return (
    <ModuleFrame
      className='container'
      fontFamily={data.fontFamily}
      backgroundImage={data.backgroundData}
      onChangeModuleWidthCoef={value => setModuleWidthCoef(value)}
    >
      <StyledText
        position={data.textBoxPosition}
        sizes={data.textBoxSize}
        font={data.textBoxFontSize * moduleWidthCoef}
        color={data.textBoxColor}
        dangerouslySetInnerHTML={{ __html: data.boxText }}>
      </StyledText>
      <StyledPlace
        className='dropzone'
        data-max-x={data.maxGridX}
        data-max-y={data.maxGridY}
        position={data.boxPosition}
        sizes={data.boxSize}
        backgroundImage={data.backgroundBox ? `url('${data.backgroundBox.image}')` : 'transparent'}
      >
        <StyledOptions>
          {data.optionNameList?.map((item, i) => <StyledOption
            key={i}
            width={data.optionWidthList[i]}
            height={data.optionHeightList[i]}
            posX={data.optionPosXList[i]}
            posY={data.optionPosYList[i]}
          >
            {data.optionImageList[i]?.image && <img src={data.optionImageList[i]?.image} alt={item}/>}
          </StyledOption>)}
        </StyledOptions>
      </StyledPlace>

      {data.emojiList.map((item, i) => {
        return (
        <StyledEmojiContainer
            key={`emoji_${id}_${i}`}
            position={item.position}
            sizes={data.emojiSize}>
            {item.imageData && (
              <StyledEmoji
                data-count={i}
                className='drag-drop'
                data-height={
                  data.emojiNameShow ? `${data.emojiSize[1] * 0.7}%` : `${data.emojiSize[1]}%`
                }
                imgHeight={data.emojiNameShow ? '70%' : '100%'}
                src={item.imageData.image}
              />
            )}
          {i === 0 && !previewMode && previewCursor && (
              <StyledCursor
                src={cursor}
                moveCursorPosition={{x: moveCursorX, y: moveCursorY}}
              />
          )}
          {data.emojiNameShow && (
              <StyledEmojiName
                color={data.emojiNameColor}
                font={data.emojiNameFontSize * moduleWidthCoef}>
                {item.name}
              </StyledEmojiName>
            )}
          </StyledEmojiContainer>
        );
      })}
      {nextButtonShow && !previewMode && <NextButton onClick={sendData} />}
    </ModuleFrame>
  );
};

const StyledText = styled.div`
  width: ${props => props.sizes[0] + '%'};
  height: ${props => props.sizes[1] + '%'};
  position: absolute;
  top: ${props => props.position[1] + '%'};
  left: ${props => props.position[0] + '%'};
  text-align: center;
  font-size: ${props => 0.9 * props.font + 'vw'} !important;
  line-height: 1.2;
  overflow: hidden;
  color: ${props => props.color};
`;

const StyledPlace = styled.div`
  width: ${props => props.sizes[0] + '%'};
  height: ${props => props.sizes[1] + '%'};
  position: absolute;
  top: ${props => props.position[1] + '%'};
  left: ${props => props.position[0] + '%'};
  background: ${props => props.backgroundImage};
  background-repeat: no-repeat;
  background-size: 99%;
  background-position: center;
  &.drop-active {
    outline: 4px solid #ccc;
  }
  &.drop-target {
    outline: 4px solid #aaa;
  }
`;

const StyledEmojiContainer = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: ${props => props.sizes[0] + '%'};
  height: ${props => props.sizes[1] + '%'};
  position: absolute;
  top: ${props => props.position[1] + '%'};
  left: ${props => props.position[0] + '%'};
`;

const StyledEmoji = styled.img`
  height: ${props => props.imgHeight};
`;

const StyledEmojiName = styled.div`
  height: 30%;
  color: ${props => props.color};
  font-size: ${props => 0.4 * props.font + 'vw'};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CursorKeyframes = (position) => keyframes`
  from {
    left: 6px;
    top: 24px;
  }
  to {
    left: ${position.x}px;
    top: ${position.y}px;
  }
`
const StyledCursor = styled.img`
  width: 158px;
  height: 158px;
  position: absolute;
  left: 6px;
  top: 24px;
  overflow: visible;
  z-index: 10;
  pointer-events: none;
  animation: ${props => CursorKeyframes(props.moveCursorPosition)} 4s linear infinite;
  @media (max-width: 1024px) {
    width: 88px;
    height: 88px;
  }
  @media (max-width: 768px) {
    width: 58px;
    height: 58px;
  }
`;

const StyledOptions = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: flex;
  justify-content: center;
  > div > img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: ${props => `-${props.size * 10 / 2}px`};
  }
`;

const StyledOption = styled.div`
  width: ${props => `${props.width}%`};
  height: ${props => `${props.height}%`};
  top: ${props => `${props.posY}%`};
  left: ${props => `${props.posX}%`};
  position: absolute;
  overflow: hidden;
  display: flex;
  justify-content: center;
  > div {
    background: ${props => props.color};
    position: relative;
  }
  > div > img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: ${props => `-${props.size * 10 / 2}px`};
  }
`;
