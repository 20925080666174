import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Left} from './Left';

export const ModuleFrame = ({children, backgroundImage, fontFamily, onChangeModuleWidthCoef, onChangeModuleHeight, className}) => {
  const container = React.useRef(null);
  const [maxWidth, setMaxWidth] = useState(window.innerHeight * 1.7778);
  const [height, setHeight] = useState(window.innerWidth * 0.5625);

  function onChangeSize(e) {
    setMaxWidth(window.innerHeight * 1.7778);
    setHeight(window.innerWidth * 0.5625);
  }

  useEffect(() => {
    let value =  container.current.offsetWidth / window.innerWidth;
    onChangeModuleWidthCoef(value);
  }, [maxWidth, height]);

  useEffect(() => {
    let value =  container.current.getBoundingClientRect().height;
    if (onChangeModuleHeight) onChangeModuleHeight(value);
  }, [maxWidth, height]);

  useEffect(() => {
    onChangeSize();
    window.addEventListener('resize', onChangeSize);
    return function () {
      window.removeEventListener('resize', onChangeSize);
    }
  }, []);

  return (
    <StyledModule className={className ? className : ''} backgroundImage={backgroundImage} height={height} maxWidth={maxWidth} fontFamily={fontFamily} ref={container}>
      {children}
    </StyledModule>
  );
};

const StyledModule = styled.div`
  touch-action: none;
  user-select: none;
  width: 100%;
  height: ${props => props.height + 'px'};
  max-height: 100%;
  max-width: ${props => props.maxWidth + 'px'};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: ${props => props.backgroundImage ? `url('${props.backgroundImage.image}')` : '#fff'};
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  font-family: ${props => props.fontFamily};
`;
