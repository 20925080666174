import React from 'react';
import styled from 'styled-components';

export const CenterLines = ({sizes, positions, zIndex = 'auto', color = '#555'}) => {
  function centerElemPosition(position, elemSize) {
    return (position * 2 + elemSize === 100) ? true : false;
  }

  return (
    <>
      {centerElemPosition(positions[0], sizes[0]) && (
        <>
          <Top color={color} zIndex={zIndex}></Top>
          <Bottom color={color} zIndex={zIndex}></Bottom>
        </>
      )}
      {centerElemPosition(positions[1], sizes[1]) && (
        <>
          <Left color={color} zIndex={zIndex}></Left>
          <Right color={color} zIndex={zIndex}></Right>
        </>
      )}
    </>
  );
};

const Top = styled.div`
  position: absolute;
  width: 2px;
  height: 8%;
  left: 0;
  top: 0;
  right: 0;
  margin: auto;
  background: ${props => props.color};
  z-index: ${props => props.zIndex};
`;

const Bottom = styled.div`
  position: absolute;
  width: 2px;
  height: 8%;
  left: 0;
  bottom: 0;
  right: -2px;
  margin: auto;
  background: ${props => props.color};
  z-index: ${props => props.zIndex};
`;

const Left = styled.div`
  position: absolute;
  width: 8%;
  height: 2px;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: ${props => props.color};
  z-index: ${props => props.zIndex};
`;

const Right = styled.div`
  position: absolute;
  width: 8%;
  height: 2px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: ${props => props.color};
  z-index: ${props => props.zIndex};
`;

