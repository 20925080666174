import React from 'react';
import styled from 'styled-components';

export const TextDetail = ({dataChoice, project}) => {

    return (
      <StyledContainer>
        <StyledTextContainer>
          {dataChoice.map((choice, i) => {
            return choice.parameters.map((item, index) => {
              return (
                  <StyledTextItem key={`${i}_${index}`}><StyledRespondent>{`Completion № ${choice.completion_id}`}</StyledRespondent>{`${item.value}`}</StyledTextItem>
              );
            })
          })}
        </StyledTextContainer>
      </StyledContainer>
    );
};

const StyledContainer = styled.div`
  padding: 20px 10px;
`;
const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const StyledTextItem = styled.p`
  margin: 10px;
  padding: 10px;
  border-bottom: 1px black solid;
`;
const StyledRespondent = styled.span`
  color: #7fd7e7;
  padding-right: 15px;
`;