import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Header } from '../components/parts/Header';
import { PageFrame } from '../components/parts/PageFrame';
import { ModuleList } from '../components/ModuleList/ModuleList';
import { SaveModal } from '../components/SaveModal';
import { DeleteModal } from '../components/DeleteModal';
import { TitleVisualModule } from '../components/ModuleList/Visual/Title';
import { SliderVisualModule } from '../components/ModuleList/Visual/Slider';
import { PhotoVisualModule } from '../components/ModuleList/Visual/Photo';
import { EmojiVisualModule } from '../components/ModuleList/Visual/Emoji';
import { TitleFieldsModule } from '../components/ModuleList/Fields/Title';
import { SliderFieldsModule } from '../components/ModuleList/Fields/Slider';
import { PhotoFieldsModule } from '../components/ModuleList/Fields/Photo';
import { EmojiFieldsModule } from '../components/ModuleList/Fields/Emoji';
import { useFetching } from '../hooks/useFetching';
import Service from '../API/service';
import { Loader } from '../components/UI/Loader';
import { SelectNumber } from '../components/UI/SelectNumber';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectData, createModule, removeModule } from '../redux/project';
import {TextVisualModule} from "../components/ModuleList/Visual/Text";
import {TextFieldsModule} from "../components/ModuleList/Fields/Text";

const visualModules = {
  Title: TitleVisualModule,
  Text: TextVisualModule,
  Slider: SliderVisualModule,
  Photo: PhotoVisualModule,
  Emoji: EmojiVisualModule,
  Closing: TitleVisualModule
};

const fieldModules = {
  Title: TitleFieldsModule,
  Text: TextFieldsModule,
  Slider: SliderFieldsModule,
  Photo: PhotoFieldsModule,
  Emoji: EmojiFieldsModule,
  Closing: TitleFieldsModule
};

export const ProjectEditor = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector((state) => state.project);
  const modules = useSelector((state) => state.project.moduleList);
  const id = useSelector((state) => state.project.url);

  const [fieldData, setFieldData] = useState(null);
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [activeModuleId, setActiveModuleId] = useState('');
  const syncRef = useRef(false);

  const [loadProject, isLoading, errorLoadingProject] = useFetching(async () => {
    const response = await Service.getProject(projectId);
    dispatch(setProjectData(response.data));
  });

  const [saveAndPreviewProject] = useFetching(async () => {
    const response = await Service.setProject(data);
    if (response) {
      navigate(`/project/${id}?sync_off=1`);
    }
  });

  useEffect(() => {
    setFieldData(null);

    if (syncRef.current === false) {
      syncRef.current = true;
      loadProject();
    }
  }, []);

  function createModuleById(moduleName) {
    dispatch(
      createModule({
        name: moduleName,
        id: Math.random().toString(16).slice(2),
        data: {}
      })
    );
  }

  function removeModuleById(id) {
    dispatch(removeModule(id));
    setActiveModuleId('');
    setFieldData(null);
  }

  function addFieldModule(data) {
    const Module = fieldModules[data.moduleName];
    setActiveModuleId(data.id);
    setFieldData(<Module {...data} />);
  }

  function onChangeNumber(fromIndex, toIndex, id) {
    let arr = [...modules];
    let element = arr[fromIndex];

    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    dispatch(
      setProjectData({
        moduleList: arr
      })
    );
    setActiveModuleId('');
    setFieldData(null);
  }

  if (errorLoadingProject) {
    return <h1>Project not found</h1>;
  }

  return (
    <StyledEditor>
      <PageFrame header={<Header onSaveModal={setSaveModalVisible} onDeleteModal={setDeleteModalVisible} onPreview={saveAndPreviewProject} />}>
        <div className="left-column">
          <h3>Add module</h3>
          <ModuleList onClickModule={createModuleById} />
        </div>
        <div className="center-column">
          {isLoading ? (
            <Loader width="50px" height="50px" />
          ) : (
            modules.map((module, i) => {
              const Module = visualModules[module.name];
              const id = module.id;
              return (
                <StyledModule key={id} backgroundColor={module.data.backgroundColor}>
                  <SelectNumber number={i} onChange={onChangeNumber} id={id} length={modules.length} />

                  <Module moduleName={module.name} className={activeModuleId === id ? 'active' : ''} number={i + 1} id={id} onRemoveModule={removeModuleById} onUpdateFields={addFieldModule} />
                </StyledModule>
              );
            })
          )}
        </div>
        <div className="right-column">{fieldData && <StyledFields>{fieldData}</StyledFields>}</div>
      </PageFrame>
      {saveModalVisible && <SaveModal setVisible={setSaveModalVisible} />}
      {deleteModalVisible && <DeleteModal setVisible={setDeleteModalVisible} />}
    </StyledEditor>
  );
};

const StyledEditor = styled.div`
  .left-column {
    width: 200px;
    background: #646a73;
    overflow: auto;
  }
  .left-column h3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 60px;
    text-align: center;
    position: sticky;
    color: #ffffff;
    top: 0;
    background: #646a73;
    margin: 0;
  }
  .center-column {
    width: 100%;
    background: #e5e5e5;
    overflow: auto;
  }
  .right-column {
    width: 370px;
    background: #646a73;
    overflow: auto;
  }
`;

const StyledModule = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  background: ${props => props.backgroundColor ? props.backgroundColor : '#fff'};
`;

const StyledFields = styled.div``;
