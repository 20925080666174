import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { InputAdornment, IconButton, TextField, Button } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AuthContext } from '../context';
import { useFetching } from '../hooks/useFetching';
import Service from '../API/service';
import { Loader } from '../components/UI/Loader';

export const Login = () => {
  const { isAuth, setIsAuth } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  const [values, setValues] = useState({
    email: '',
    password: ''
  });

  const [fetchLogin, isLoading, error] = useFetching(async () => {
    const response = await Service.login(values);

    if (response.data && response.data.access_token) {
      Service.setAccessToken(response.data.access_token);
      setIsAuth(true);
      localStorage.setItem('tkn', response.data.access_token);
    }
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <StyledForm>
      <TextField type="email" value={values.email} onChange={handleChange('email')} margin="normal" fullWidth label="Email" />

      <TextField
        value={values.password}
        onChange={handleChange('password')}
        type={showPassword ? 'text' : 'password'}
        margin="normal"
        fullWidth
        label="Password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={(e) => setShowPassword(!showPassword)} onMouseDown={handleMouseDownPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Button onClick={fetchLogin} disabled={isLoading ? true : false} className="button" variant="contained">
        {isLoading ? <Loader width="30px" height="30px" /> : 'Log in'}
      </Button>
    </StyledForm>
  );
};

const StyledForm = styled.div`
  width: 100%;
  max-width: 300px;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .button {
    overflow: hidden;
    font-size: 20px;
    width: 100%;
    height: 50px;
    margin-top: 10px;
  }
`;
