import React, {useRef, useState, useEffect} from 'react';
import styled from 'styled-components';
import {Button} from '@mui/material';
import {ColorPickerButton} from '../../UI/ColorPickerButton';
import {SelectFontFamily} from '../../UI/SelectFontFamily';
import {Slider} from '../../UI/Slider';
import {InputFileButton} from '../../UI/InputFileButton';
import {LinkButton} from '../../UI/LinkButton';

export const TextFieldsModule = ({
  number,
  id,
  textBoxFontSize,
  inputBoxFontSize,
  fontFamily,
  setFontFamily,
  buttonFontSize,
  setTextBoxFontSize,
  setInputBoxFontSize,
  setButtonFontSize,
  textBoxColor,
  inputBoxColor,
  setTextBoxColor,
  setInputBoxColor,
  setBackgroundData,
  backgroundData,
  backgroundColor,
  setBackgroundColor,
  buttonColor,
  setButtonColor,
  buttonTextColor,
  buttonRadius,
  setButtonRadius,
  setButtonTextColor,
  removeModule
}) => {
  return (
    <StyledFields>
      <StyledNumber>{number}</StyledNumber>
      <ColorPickerButton name="Background color" color={backgroundColor} onChangeColor={setBackgroundColor}/>
      <InputFileButton id={id} text="Add Background Image" value={backgroundData} onChange={setBackgroundData}/>
      <SelectFontFamily onChange={setFontFamily} value={fontFamily}/>
      <hr/>
      <h3>Title box</h3>
      <ColorPickerButton name="Color" color={textBoxColor} onChangeColor={setTextBoxColor}/>
      <Slider name="Font size" value={textBoxFontSize} onChangeValue={(e) => setTextBoxFontSize(e.target.value)}/>
      <hr/>
      <h3>Input box</h3>
      <ColorPickerButton name="Color" color={inputBoxColor} onChangeColor={setInputBoxColor}/>
      <Slider name="Font size" value={inputBoxFontSize} onChangeValue={(e) => setInputBoxFontSize(e.target.value)}/>
      <hr/>
      <h3>Button</h3>
      <ColorPickerButton name="Button color" color={buttonColor} onChangeColor={setButtonColor}/>
      <ColorPickerButton name="Button text color" color={buttonTextColor} onChangeColor={setButtonTextColor}/>
      <Slider name="Font size" value={buttonFontSize} onChangeValue={(e) => setButtonFontSize(e.target.value)}/>
      <Slider max={50} name="Button radius" value={buttonRadius} onChangeValue={(e) => setButtonRadius(e.target.value)}/>
      <hr/>
      <LinkButton text="Delete this module" onClick={removeModule}/>
    </StyledFields>
  );
};

const StyledFields = styled.div`
  user-select: none;
  padding: 5px;
  h3 {
    color: #fff;
    font-weight: normal;
    text-align: center;
    margin: 0;
  }
`;

const StyledNumber = styled.div`
  border: 1px solid #aaa;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
`;
