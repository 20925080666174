import React, {useRef, useState, useEffect} from 'react';
import styled from 'styled-components';
import {ModuleFrame} from '../../parts/ModuleFrame';

export const TitleModule = ({data, onNext}) => {
  const container = React.useRef(null);
  const [moduleWidthCoef, setModuleWidthCoef] = useState(0);

  return (
    <ModuleFrame fontFamily={data.fontFamily} backgroundImage={data.backgroundData} onChangeModuleWidthCoef={(value) => setModuleWidthCoef(value)}>
      <StyledText
        position={data.textBoxPosition}
        sizes={data.textBoxSize}
        font={data.textBoxFontSize * moduleWidthCoef}
        color={data.textBoxColor}
        dangerouslySetInnerHTML={{__html: data.boxText}}
      ></StyledText>

      <StyledButton
        position={data.buttonPosition}
        sizes={data.buttonSize}
        font={data.buttonFontSize * moduleWidthCoef}
        textColor={data.buttonTextColor}
        radius={data.buttonRadius}
        color={data.buttonColor}
        onClick={(e) => onNext()}
        dangerouslySetInnerHTML={{__html: data.buttonText}}
      ></StyledButton>

    </ModuleFrame>
  );
};

const StyledText = styled.div`
  width: ${props => props.sizes[0] + '%'};
  height: ${props => props.sizes[1] + '%'};
  position: absolute;
  top: ${props => props.position[1] + '%'};
  left: ${props => props.position[0] + '%'};
  text-align: center;
  font-size: ${props => 0.9 * props.font + 'vw'} !important;
  line-height: 1.2;
  overflow: hidden;
  color: ${props => props.color};
`;

const StyledButton = styled.div`
  cursor: pointer;
  user-select: none;
  width: ${props => props.sizes[0] + '%'};
  height: ${props => props.sizes[1] + '%'};
  background: ${props => props.color};
  color: ${props => props.textColor};
  position: absolute;
  top: ${props => props.position[1] + '%'};
  left: ${props => props.position[0] + '%'};
  border: 3px solid ${props => props.textColor};
  border-radius: ${props => props.radius + 'px'};
  font-size: ${props => 0.5 * props.font + 'vw'} !important;
  line-height: 1.2;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &:focus-visible {
    outline: none;
  }
`;
