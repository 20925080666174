import React from 'react';
import styled from 'styled-components';

export const Loader = ({width, height, margin, color = '#353A3F'}) => {
  return (
    <StyledLoader width={width} height={height} margin={margin} color={color}>
      <div className="loader"></div>
    </StyledLoader>
  );
};

const StyledLoader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${props => props.margin ? props.margin : '5% 0'};

  .loader {
    width: ${props => props.width ? props.width : '100px'};
    height: ${props => props.height ? props.height : '100px'};
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: transparent;
    border-right-color: ${props => props.color};
    border-bottom-color: transparent;
    border-left-color: ${props => props.color};
    animation: rotate-loading 1.5s linear 0s infinite normal;
    transform-origin: 50% 50%;
  }

  @keyframes rotate-loading {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
  }
`;

