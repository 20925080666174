import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import {useParams, useLocation, Link as RouterLink} from 'react-router-dom';
import { PageFrame } from '../components/parts/PageFrame';
import { HeaderReport } from '../components/parts/HeaderReport';
import Service from '../API/service';
import { useFetching } from '../hooks/useFetching';
import { Loader } from '../components/UI/Loader';
import { EmojiDetail } from "../components/ModuleList/Statistics/EmojiDetail";
import {Link} from "@mui/material";

export const ProjectEmojiReport = () => {
    const { projectId, emojiId, moduleId } = useParams();
    const syncRef = useRef(false);
    const [statData, setStatData] = useState(null);
    const [statDataChoice, setDataChoice] = useState(null);
    const [statProject, setProject] = useState(null);

    const [loadData, isLoading, error] = useFetching(async () => {
        const statistics = await Service.getStatisticsByProject(projectId);
        const project = await Service.getProject(projectId);
        const parameters = await Service.getParametersEmojiByProject(projectId, emojiId);

        setProject(project)
        setStatData(statistics.data)
        setDataChoice(parameters.data)
    });

    useEffect(() => {
        if (syncRef.current === false) {
            syncRef.current = true;
            loadData();
        }
    }, []);

    return (
      <StyledStat>
        {isLoading ? (
          <Loader />
        ) : error ? (
          <h1>Statistics not found</h1>
        ) : (
          statDataChoice && (
            <PageFrame header={<HeaderReport id={statData.url} />}>
              <Link component={RouterLink} to={`/project-report/${projectId}`}>
                <StyledReturnButton/>
              </Link>
              <StyledContent>
                <Project color={statData.color}>
                  <EmojiDetail dataChoice={statDataChoice} project={statProject}/>
                </Project>
              </StyledContent>
            </PageFrame>
          )
        )}
      </StyledStat>
    );
};
const StyledReturnButton = styled.div`
  width: 0;
  height: 0;
  border-bottom: 30px solid transparent;
  border-top: 30px solid transparent;
  border-right: 50px solid green;
  position: absolute; 
  top: 150px;
  left: 96px;
  transition-duration: 0.5s;
  &:hover {
    left: 92px;
  }
`
const StyledStat = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
`;

const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #e5e5e5;
`;

const Project = styled.div`
  border-radius: 10px;
  background: #fff;
  min-height: 410px;
  border-top: 15px solid ${props => `${props.color}`};
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  margin: 2% 5%;
  overflow: hidden;
`;
