import React, {useEffect, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import Slider from '@mui/material/Slider';
import {NextButton} from '../../UI/NextButton';
import {ModuleFrame} from '../../parts/ModuleFrame';
import cursor from "../../../assets/images/cursor.png";

export const SliderModule = ({number, id, data, onNext, previewMode = false}) => {
  const [nextButtonShow, setNextButtonShow] = useState(false);
  const [sliderList, setSliderList] = useState([]);
  const [previewSliderCursor, setPreviewSliderCursor] = useState(true)
  const [moduleHeight, setModuleHeight] = useState([]);
  const [moduleWidthCoef, setModuleWidthCoef] = useState(0);

  useEffect(() => {
    let list = [...data.sliderImageList.filter(i => i)].map(i => {
      return Object.assign(i, {
        width: 100,
        value: 50
      });
    });
    setSliderList(list);
    setNextButtonShow(false);
  }, [data.sliderImageList]);

  function setWidth(i, value) {
    let newArr = [...sliderList];
    setPreviewSliderCursor(true)
    newArr[i].width = value;
    setSliderList([...newArr]);
  }

  function changeSlider(e, i) {
    let newArr = [...sliderList];
    newArr[i].value = e.target.value;
    setSliderList([...newArr]);
    setNextButtonShow(true);
  }

  function getLeft(i) {
    let value = sliderList[i].value;
    if (previewSliderCursor === true && value !== 50) {
      setPreviewSliderCursor(false)
    }
    let width = sliderList[i].width;
    return `calc(${value}% + (${width / 2 - value * (width / 100)}px))`;
  }

  function sendData(e) {
    setPreviewSliderCursor(true)
    onNext({
      name: 'Slider',
      id: id,
      // data: [...sliderList].map(i => i.value)
      data: [...sliderList].map((item, i) => ({
        text: data.sliderNameList[i],
        value: item.value,
      }))
    });
  }

  return (
    <ModuleFrame fontFamily={data.fontFamily} backgroundImage={data.backgroundData} onChangeModuleWidthCoef={(value) => setModuleWidthCoef(value)} onChangeModuleHeight={(value) => setModuleHeight(value)}>
      <StyledText
        position={data.textBoxPosition}
        sizes={data.textBoxSize}
        font={data.textBoxFontSize * moduleWidthCoef}
        color={data.textBoxColor}
        dangerouslySetInnerHTML={{__html: data.boxText}}
      ></StyledText>

      <StyledSlider
        color={data.sliderLineColor}
        position={data.sliderBoxPosition}
        sizes={data.sliderBoxSize}
      >
        <StyledOption>
          {data.optionNameList?.map((item, i) => <div key={i}>{data.optionImageList[i]?.image && <img src={data.optionImageList[i]?.image} alt={item}/>}</div>)}
        </StyledOption>
        {
          sliderList && sliderList.map((item, i) => {
            return (
              <div key={`${number}_${i}`}>
                {i === 0 && !previewMode && previewSliderCursor && (
                  <StyledCursor
                    src={cursor}
                  />
                )}
                <CustomImg
                  moduleHeight={moduleHeight}
                  size={data.sliderImageSize}
                  src={item.image}
                  alt={item.name}
                  left={getLeft(i)}
                  onLoad={(e) => setWidth(i, e.target.offsetWidth)}
                />
                <CustomImgCover hsize={data.sliderImageSize}></CustomImgCover>
                <CustomSlider
                  moduleHeight={moduleHeight}
                  hsize={data.sliderImageSize}
                  w={item.width}
                  defaultValue={50}
                  onChange={(e) => changeSlider(e, i)}
                />
              </div>
            );
          })
        }
      </StyledSlider>

      { nextButtonShow && !previewMode &&
          <NextButton onClick={(e) => sendData(e)}/>
      }
    </ModuleFrame>
  );
};

const StyledText = styled.div`
  width: ${props => props.sizes[0] + '%'};
  height: ${props => props.sizes[1] + '%'};
  position: absolute;
  top: ${props => props.position[1] + '%'};
  left: ${props => props.position[0] + '%'};
  text-align: center;
  font-size: ${props => 0.9 * props.font + 'vw'} !important;
  line-height: 1.2;
  overflow: hidden;
  color: ${props => props.color};
`;

const StyledSlider = styled.div`
  width: ${props => props.sizes[0] + '%'};
  height: ${props => props.sizes[1] + '%'};
  position: absolute;
  top: ${props => props.position[1] + '%'};
  left: ${props => props.position[0] + '%'};
  display: flex;
  justify-content: center;
  flex-direction: column;
  user-select: none;
  > div {
    width: calc(100% - 40px);
    height: 3px;
    background: ${props => props.color};
    margin: auto;
    position: relative;
    cursor: pointer;
  }
  > div:before {
    content: '';
    background: ${props => props.color};
    width: 13px;
    height: 13px;
    position: absolute;
    left: -13px;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    margin: auto;
  }
  > div:after {
    content: '';
    background: ${props => props.color};
    width: 13px;
    height: 13px;
    position: absolute;
    right: -13px;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    margin: auto;
  }
`;

const CustomImg = styled.img`
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: ${props => props.left};
  transform: translateX(-50%);
  height: ${props => `${props.moduleHeight / 100 * props.size}px`};
  @media (max-width: 1248px) {
    height: ${props => `${props.moduleHeight / 60 * props.size}px`};
  }
`;

const CustomImgCover = styled.div`
  height: ${props => `${props.moduleHeight / 100 * props.hsize}px`};
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

const CustomSlider = styled(Slider)`
  height: ${props => `${props.moduleHeight / 100 * props.hsize}px`} !important;
  position: absolute !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: ${props => props.w ? `calc(100% - ${props.w}px)` : '100%' } !important;
  .MuiSlider-track, .MuiSlider-rail, .MuiSlider-thumb {
    display: none;
  }
`;
const CursorKeyframes = keyframes`
  from {
    left: 45%;
  }
  50%{
    left: 50%;
  }
  to {
    left: 40%;
  }
`
const StyledCursor = styled.img`
  width: 158px;
  height: 158px;
  position: absolute;
  left: 45%;
  overflow: visible;
  z-index: 10;
  pointer-events: none;
  animation: ${CursorKeyframes} 3s linear infinite;
  @media (max-width: 1024px) {
    width: 88px;
    height: 88px;
  }
  @media (max-width: 768px) {
    width: 58px;
    height: 58px;
  }
`;

const StyledOption = styled.p`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: flex;
  justify-content: center;
  > div {
    width: 100%;
    background: ${props => props.color};
    position: relative;
  }
  > div > img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: ${props => `-${props.size * 10 / 2}px`};
  }
`;
