import React, {createRef, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {create as heatmapCreate} from 'heatmap.js';
import {Link, useParams} from 'react-router-dom';
import {EmojiModule} from "../Page/Emoji";

export const Emoji = ({ number, statData, projectData, moduleId }) => {
  const { projectId } = useParams();
  const emojiList = projectData.emojiList;
  const mapImage = projectData.backgroundBox?.image;
  const mapName = projectData.backgroundBox?.name;
  const placeRef = useRef(statData.map(() => createRef()));
  const canvasRenderRef = useRef(false);
  let minWidth = 300;

  let wCoef = (projectData.boxSize[0] * 16) / 100;
  let hCoef = (projectData.boxSize[1] * 9) / 100;
  let width = minWidth + 'px';
  let height = (minWidth / wCoef) * hCoef + 'px';

  function toPxWidth(value, i) {
    let parentWidth = placeRef.current[i].current?.clientWidth || 0;
    return Number(((parseFloat(value) * parseFloat(parentWidth)) / projectData.maxGridX).toFixed());
  }

  function toPxHeight(value, i) {
    let parentHeight = placeRef.current[i].current?.clientHeight || 0;
    return Number(
      ((parseFloat(value) * parseFloat(parentHeight)) / projectData.maxGridY).toFixed()
    );
  }

  useEffect(() => {
    if (canvasRenderRef.current === false) {
      canvasRenderRef.current = true;

      placeRef.current.map((item, i) => {
        const heatmapInstance = heatmapCreate({
          container: item.current
        });

        let max = 0;

        const data = statData[i].map.map(params => {
          max = Math.max(max, params[2]);
          return { x: toPxWidth(params[0], i), y: toPxHeight(params[1], i), value: params[2] };
        });

        heatmapInstance.setData({
          max: max,
          data: data
        });
      });
    }
  });
  return (
    <StyledContainer>
      <StyledModule>
        <EmojiModule data={projectData} previewMode={true}/>
      </StyledModule>
      <StyledEmojiContainer>
        {statData.map((item, i) => {
          return (
            <StyledEmojiItem key={`emoji_${number}_${i}`}>
              <StyledEmojiHeader>
                <h4>
                  {emojiList[i].name} ({item.engagements})
                </h4>
                <img src={emojiList[i]?.imageData?.image} alt={item.name} />
              </StyledEmojiHeader>
                <Link to={{
                  pathname: `/project-report-emoji-list/${projectId}/${emojiList[i].name}/${moduleId}`,
                }}>
                  <StyledEmojiMap
                      ref={placeRef.current[i]}
                      width={width}
                      height={height}
                      background={`url('${mapImage}')`}
                      alt={mapName}
                  />
                </Link>
              {item?.options &&
                <StyledItemOptionContainer>
                  <h5>Options</h5>
                  {
                    item.options?.map((option, j) => {
                      return <div>{option.name} - <span>Count</span>: {option.count} | {option.percents}%</div>
                    })
                  }
                </StyledItemOptionContainer>
              }
            </StyledEmojiItem>
          );
        })}
      </StyledEmojiContainer>
    </StyledContainer>
  );
};
const StyledModule = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  background: ${props => props.backgroundColor ? props.backgroundColor : '#fff'};
`;

const StyledContainer = styled.div`
  padding: 20px 10px;
  width: 300px;
`;

const StyledImg = styled.img`
  width: 100%;
  margin-bottom: 20px;
`;

const StyledEmojiContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const StyledEmojiItem = styled.div`
  width: 100%;
  margin: 5px 0;
`;

const StyledEmojiHeader = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  & > h4 {
    margin: 0;
  }
  & > img {
    height: 30px;
  }
`;

const StyledEmojiMap = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  margin: auto;
  background-image: ${props => props.background};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

const StyledItemOptionContainer = styled.div`
  padding: 0 10%;
`;
