import React, {useContext} from 'react';
import {Navigate, Routes, Route} from 'react-router-dom';
import {privateRoutes, publicRoutes} from '../router';
import {AuthContext} from '../context';
import styled from 'styled-components';
import { Loader } from '../components/UI/Loader';

export const AppRouter = () => {
  const {isAuth, isLoading} = useContext(AuthContext);

  if (isLoading) {
    return (
      <StyledLoader>
        <Loader/>
      </StyledLoader>
    );
  }

  return (
    isAuth
      ?
      <Routes>
        {privateRoutes.map(route =>
          <Route
            key={route.path}
            element={route.component}
            path={route.path}
            exact={route.exact}
          />
        )}
        <Route path="*" element={<Navigate to ="/projects" />}/>
      </Routes>
      :
      <Routes>
        {publicRoutes.map(route =>
          <Route
            key={route.path}
            element={route.component}
            path={route.path}
            exact={route.exact}
          />
        )}
        <Route path="*" element={<Navigate to ="/login" />}/>
      </Routes>
  );
};

const StyledLoader = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;
