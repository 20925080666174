import React from 'react';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

export const SelectCustom = ({title, list, value, onChange}) => {
  return (
    <StyledContainer>
      <StyledFormControl fullWidth size="small">
        <InputLabel id="font-family-select">{title}</InputLabel>
        <StyledSelect
            labelId="font-family-select"
            value={value}
            label={title}
            input={<OutlinedInput label={title} />}
            onChange={(e) => onChange(e.target.value)}
          >
          {
            list.map((i, n) => <MenuItem key={title+n} value={i}>{i}</MenuItem>)
          }
        </StyledSelect>
      </StyledFormControl>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 90%;
  margin: auto;
`;
const StyledFormControl = styled(FormControl)`
  margin: 10px auto !important;
  & input {
    color: #fff;
  }
  & label {
    z-index: 0;
    color: #fff;
  }
  & fieldset,
  & fieldset:hover {
    border: 1px solid #fff !important;
  }
  & label.Mui-focused {
    color: #fff;
  }
  & .MuiOutlinedInput-root {
    height: 44px;
    &.Mui-focused fieldset {
      border: 1px solid #fff !important;
    }
  }
  & svg {
    fill: #fff;
  }
`;

const StyledSelect = styled(Select)`
  color: #fff !important;
`;
