import React from 'react';
import styled from 'styled-components';
import {ColorPicker} from '../ColorPicker.jsx';

export const ColorPickerButton = ({color, onChangeColor, name, format}) => {
  return (
    <StyledColorPicker>
      <ColorPicker defaultColor={color} onSetColor={onChangeColor} format={format}/>
      <span>{name}</span>
    </StyledColorPicker>
  );
};

const StyledColorPicker = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 5px;
  > span {
    margin-left: 5px;
  }
`;

