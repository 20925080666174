import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/images/logo.svg';
import { useParams, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { Left } from '../components/parts/Left';
import { Grid, Button, Link } from '@mui/material';
import { Project } from '../components/parts/Project';
import Service from '../API/service';
import { useFetching } from '../hooks/useFetching';
import { Loader } from '../components/UI/Loader';

export const Projects = () => {
  const [projects, setProjects] = useState([]);
  const syncRef = useRef(false);
  const navigate = useNavigate();
  const [fetchProjects, isProjectsLoading, error] = useFetching(async () => {
    const response = await Service.getProjects();
    setProjects(response.data);
  });

  const [createAndEditProject] = useFetching(async () => {
    let url = Math.random().toString(16).slice(2);

    const response = await Service.setProject({
      color: '#eee',
      name: 'Survey',
      last_link: '',
      url: url,
      is_published: false,
      moduleList: []
    });

    if (response) {
      navigate(`/project-editor/${url}`);
    }
  });

  useEffect(() => {
    if (syncRef.current === false) {
      syncRef.current = true;
      fetchProjects();
    }
  }, []);

  return (
    <StyledProjects>
      <Left className="left" />
      <Grid className="right" container spacing={0}>
        <Grid className="logo" item xs={6}>
          <Link component={RouterLink} to="/">
            <img src={logo} alt="logo" />
          </Link>
        </Grid>
        <Grid className="create-button-container" item xs={6}>
          <Button className="create-button" variant="contained" size="large" onClick={createAndEditProject}>
            CREATE NEW
          </Button>
        </Grid>
        <Grid item xs={12}>
          {isProjectsLoading ? (
            <Loader />
          ) : (
            <div className="projects">
              {projects.length > 0 &&
                projects.map((project, i) => (
                  <StyledProject key={i} color={project.color}>
                    <Project setProjects={setProjects} url={project.url} name={project.name} color={project.color} date={project.created_at} pageviews={project.pageviews} engagements={project.engagements} />
                  </StyledProject>
                ))}
            </div>
          )}
        </Grid>
      </Grid>
    </StyledProjects>
  );
};

const StyledProjects = styled.div`
  background: #e5e5e5;
  display: flex;
  align-items: flex-start;
  height: 100vh;
  overflow: auto;

  .right {
    margin-left: 85px;
  }
  .logo {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 2% 1% 0 5%;
  }
  .create-button-container {
    align-items: flex-start;
    display: flex;
    justify-content: flex-end;
    padding: 2% 5% 0 1%;
  }
  .logo img {
    width: 100%;
    max-width: 300px;
  }
  .create-button {
  }
  .projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
`;

const StyledProject = styled.div`
  width: 350px;
  background: #f4f6f9;
  height: 410px;
  margin: 2% 1%;
  border-radius: 8px;
  overflow: hidden;
  border-top: 15px solid ${props => `${props.color}`};
`;
