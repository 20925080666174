import React from 'react';
import styled from 'styled-components';
import { Link } from '@mui/material';
import title from '../../assets/images/modules/title.png';
import text from '../../assets/images/modules/text.png';
import introduction from '../../assets/images/modules/introduction.png';
import cardSord from '../../assets/images/modules/card_sort.png';
import photo from '../../assets/images/modules/photo.png';
import columns from '../../assets/images/modules/columns.png';
import emoji from '../../assets/images/modules/emoji.png';
import slider from '../../assets/images/modules/slider.png';
import closing from '../../assets/images/modules/closing.png';

export const ModuleList = ({onClickModule}) => {
  return (
    <StyledList>
      <img src={title} onClick={() => onClickModule('Title')} alt="title" />
      <img src={text} onClick={() => onClickModule('Text')} alt="text" />
      <img className="hide" src={introduction} alt="introduction" />
      <img className="hide" src={cardSord} alt="cardSord" />
      <img src={photo} onClick={() => onClickModule('Photo')} alt="photo" />
      <img className="hide" src={columns} alt="columns" />
      <img src={emoji} onClick={() => onClickModule('Emoji')} alt="emoji" />
      <img src={slider} onClick={() => onClickModule('Slider')} alt="slider" />
      <img src={closing} onClick={() => onClickModule('Closing')} alt="closing" />
    </StyledList>
  );
};

const StyledList = styled.div`
  img {
    width: calc(100% - 40px);
    height: 65px;
    margin: 5px 20px;
    cursor: pointer;
  }
  img.hide {
    cursor: default;
    opacity: 0.2;
  }
`;
