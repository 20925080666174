import React from 'react';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import {Button, IconButton, Link} from '@mui/material';
import styled from 'styled-components';
import {useFetching} from "../../hooks/useFetching";
import Service from "../../API/service";
import edit from '../../../src/assets/images/icons/edit.svg';
import copy from '../../../src/assets/images/icons/copy.svg';

export const Project = ({setProjects, url, name, color, date, pageviews, engagements}) => {
  const {projectId} = useParams();
  const navigate = useNavigate();

  const [copyProject] = useFetching(async () => {
      await Service.copyProject(url);
      const response = await Service.getProjects();
      setProjects(response.data);
  });

  return (
    <StyledProject>
      <div className="header">
        <ProjectName>{name}</ProjectName>
        <div>
          <IconButton component={RouterLink} to={`/project-editor/${url}`}>
            <IconButtonImage src={edit}></IconButtonImage>
          </IconButton>
          <IconButton onClick={copyProject}>
            <IconButtonImage src={copy}></IconButtonImage>
          </IconButton>
        </div>
      </div>
      <h4>Published {date}</h4>
      <div className="place">
        <div className="info">
          <div className="name">Engagements</div>
          <div className="value">{engagements}</div>
          <hr/>
          <div className="name">Pageviews</div>
          <div className="value">{pageviews}</div>
        </div>
        <CustomButton colorvalue={color} className="create-button" variant="contained" size="large" component={RouterLink} to={`/project-report/${url}`}>
          See full report
        </CustomButton>
      </div>
    </StyledProject>
  );
};

const IconButtonImage = styled.img`
  width: 20px;
  height: 20px;
`;
const ProjectName = styled.h2`
  max-width: 75%;
`;

const StyledProject = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    padding: 0 6%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin-top: 20px;
  }
  h2 {
    margin: 0;
  }
  h4 {
    padding: 0 6%;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
  }
  hr {
    width: 100%;
  }
  .place {
    background: #fff;
    padding: 0 6%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .name {
    font-weight: 300;
  }
  .value {
    font-weight: 800;
    font-size: 27px;
  }
  .info {
    height 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
`;

const CustomButton = styled(Button)`
  && {
    background: ${(props) => `${props.colorvalue}`} !important;
    width: 100%;
    display: flex;
    margin: auto;
    border-radius: 6px;
    font-weight: 700;
    font-size: 19px;
    text-transform: none;
    text-shadow: 0px 0px 3px #888;
  }
`;

const CustomLink = styled(Link)`
  && {
    color: #000;
    font-size: 13px;
    text-decoration-color: #000;
  }
`;
