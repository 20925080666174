import React, {useState, useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {AppRouter} from './components/AppRouter';
import {AuthContext} from './context';
import {ThemeProvider} from '@mui/material';
import {theme} from './theme';
import Service from './API/service';

export default function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('tkn')) {
      Service.setAccessToken(localStorage.getItem('tkn'));
      setIsAuth(true);
    }

    setLoading(false);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={{
        isAuth,
        setIsAuth,
        isLoading
      }}>
        <BrowserRouter>
          <AppRouter/>
        </BrowserRouter>
      </AuthContext.Provider>
    </ThemeProvider>
  );
}
