import {Projects} from '../pages/Projects';
import {ProjectEditor} from '../pages/ProjectEditor';
import {Project} from '../pages/Project';
import {ProjectReport} from '../pages/ProjectReport';
import {Login} from '../pages/Login';
import {ProjectEmojiReport} from "../pages/ProjectEmojiReport";
import {ProjectTextReport} from "../pages/ProjectTextReport";
import {ClientReport} from "../pages/ClientReport";

export const privateRoutes = [
  {path: '/projects', component: <Projects/>, exact: false},
  {path: '/project-editor', component: <ProjectEditor/>, exact: true},
  {path: '/project-editor/:projectId', component: <ProjectEditor/>, exact: true},
  {path: '/project/:projectId', component: <Project/>, exact: false},
  {path: '/client-report/:projectId', component: <ClientReport/>, exact: false},
  {path: '/project-report/:projectId', component: <ProjectReport/>, exact: false},
  {path: '/project-report-emoji-list/:projectId/:emojiId/:moduleId', component: <ProjectEmojiReport/>, exact: false},
  {path: '/project-report-text-list/:projectId/:moduleId', component: <ProjectTextReport/>, exact: false},
];

export const publicRoutes = [
  {path: '/login', component: <Login/>, exact: false},
  {path: '/project/:projectId', component: <Project/>, exact: false},
  {path: '/client-report/:projectId', component: <ClientReport/>, exact: false},
];
