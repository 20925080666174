import React from 'react';
import styled from 'styled-components';
import {Switch as MuiSwitch} from '@mui/material';

export const Switch = ({checked, setChecked}) => {
  return (
    <CustomSwitch checked={checked} onChange={(e) => setChecked(e.target.checked)} />
  );
};

const CustomSwitch = styled(MuiSwitch)`
  &.MuiSwitch-root {
    width: 54px;
    height: 34px;
    border-radius: 30px;
    padding: 0;
    .MuiSwitch-switchBase {
      padding: 4px;
      .MuiSwitch-thumb {
        width: 26px;
        height: 26px;
      }
      .MuiSwitch-thumb:before: {
        background: red;
      }
    }
  }

  .Mui-checked {
    color: #fff !important;
    .MuiSwitch-thumb {
      background: #fff;
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.69156 8.62704C5.12865 9.18995 4.216 9.18995 3.65309 8.62704L0.304047 5.27799C-0.097947 4.876 -0.0979477 4.22424 0.304047 3.82224C0.706041 3.42025 1.3578 3.42025 1.7598 3.82224L4.67233 6.73477L10.4677 0.939424C10.8697 0.53743 11.5214 0.537428 11.9234 0.939423C12.3254 1.34142 12.3254 1.99318 11.9234 2.39517L5.69156 8.62704Z' fill='%23FF3377'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .MuiSwitch-track {
    background: #475284;
  }

  .Mui-checked+.MuiSwitch-track {
    background: #FF3377 !important;
    opacity: 1 !important;
  }
`;

