import React, {useRef, useState, useMemo, useEffect, useDeferredValue} from 'react';
import styled from 'styled-components';
import {DraggableBox} from '../../UI/DraggableBox';
import {useDispatch, useSelector} from 'react-redux';
import {addModuleData} from '../../../redux/project';
import {Slider} from "../../UI/Slider";

export const SliderVisualModule = ({moduleName, number, id, className, onUpdateFields, onRemoveModule}) => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.project.moduleList).find(module => module.id === id)?.data || {};

  const [backgroundColor, setBackgroundColor] = useState(data.backgroundColor || '#fff');
  const [backgroundData, setBackgroundData] = useState(data.backgroundData || null);
  const [textBoxPosition, setTextBoxPosition] = useState(data.textBoxPosition || [25, 10]);
  const [textBoxSize, setTextBoxSize] = useState(data.textBoxSize || [50, 10]);
  const [textBoxColor, setTextBoxColor] = useState(data.textBoxColor || '#333');
  const [fontFamily, setFontFamily] = useState(data.fontFamily || 'Poppins-Regular');
  const [textBoxFontSize, setTextBoxFontSize] = useState(data.textBoxFontSize || 3);
  const [boxText, setBoxText] = useState(data.boxText || 'Changing placeholder text here');
  const [sliderNameList, setSliderNameList] = useState(data.sliderNameList || [...Array(6)].map((i, n) => `Item #${n + 1}`));
  const [sliderImageList, setSliderImageList] = useState(data.sliderImageList || [...Array(6)].map(i => null));
  const [optionNameList, setOptionNameList] = useState(data.optionNameList || []);
  const [optionSizeList, setOptionSizeList] = useState(data.optionSizeList || [...Array(optionNameList.length)].map(i => 100 / optionNameList.length));
  const [optionImageList, setOptionImageList] = useState(data.optionImageList || []);
  const [sliderBoxPosition, setSliderBoxPosition] = useState(data.sliderBoxPosition || [5, 40]);
  const [sliderBoxSize, setSliderBoxSize] = useState(data.sliderBoxSize || [90, 50]);
  const [sliderImageSize, setSliderImageSize] = useState(data.sliderImageSize || 3);
  const [sliderLineColor, setSliderLineColor] = useState(data.sliderLineColor || '#333');

  const container = React.useRef(null);
  const boxTextRef = useRef(boxText);

  const [textBoxLinePosition, setTextBoxLinePosition] = useState([0, 0]);
  const [sliderBoxLinePosition, setSliderBoxLinePosition] = useState([0, 0]);

  const [moduleWidthCoef, setModuleWidthCoef] = useState(0);
  const defferedCoef = useDeferredValue(moduleWidthCoef);

  const memoBackgroundImage = useMemo(() => {
    return backgroundData ? `url('${backgroundData.image}')` : null;
  }, [backgroundData]);

  function removeModule() {
    onRemoveModule(id);
  }

  function updateFields() {
    onUpdateFields({
      moduleName,
      number,
      id,
      textBoxColor,
      textBoxFontSize,
      setTextBoxColor,
      setTextBoxFontSize,
      fontFamily,
      setFontFamily,
      setBackgroundData,
      backgroundData,
      backgroundColor,
      setBackgroundColor,
      sliderImageList,
      optionSizeList,
      setOptionSizeList,
      setSliderImageList,
      optionImageList,
      setOptionImageList,
      optionNameList,
      setOptionNameList,
      sliderNameList,
      setSliderNameList,
      sliderImageSize,
      setSliderImageSize,
      sliderLineColor,
      setSliderLineColor,
      removeModule
    });
  }

  useEffect(updateFields, [textBoxColor, textBoxFontSize, fontFamily, sliderImageList, optionImageList, optionNameList, optionSizeList, sliderNameList, sliderImageSize, sliderLineColor, backgroundData, backgroundColor]);

  useEffect(() => {
    dispatch(addModuleData({
      id: id,
      data: {
        textBoxPosition,
        textBoxSize,
        textBoxColor,
        boxText,
        backgroundData,
        backgroundColor,
        fontFamily,
        textBoxFontSize,
        sliderBoxSize,
        sliderBoxPosition,
        sliderImageSize,
        sliderLineColor,
        sliderImageList,
        sliderNameList,
        optionNameList,
        optionSizeList,
        optionImageList,
      }
    }));
  }, [
    textBoxPosition,
    textBoxSize,
    textBoxColor,
    boxText,
    backgroundData,
    backgroundColor,
    fontFamily,
    textBoxFontSize,
    sliderBoxSize,
    sliderBoxPosition,
    sliderImageSize,
    sliderLineColor,
    sliderImageList,
    optionNameList,
    optionSizeList,
    optionImageList,
    sliderNameList
  ]);

  function onChangeSize(e) {
    let value =  container.current.offsetWidth / window.innerWidth;
    setModuleWidthCoef(value);
  }

  useEffect(() => {
    onChangeSize();
    window.addEventListener('resize', onChangeSize);
    return function () {
      window.removeEventListener('resize', onChangeSize);
    }
  }, []);

  return (
    <StyledModule backgroundImage={memoBackgroundImage} ref={container} className={className} onClick={updateFields}>
      <DraggableBox
        position={textBoxPosition}
        setPosition={setTextBoxPosition}
        sizes={textBoxSize}
        setSizes={setTextBoxSize}
        linePosition={textBoxLinePosition}
        setLinePosition={setTextBoxLinePosition}
        parentRef={container}
      >
        <StyledText
          fontFamily={fontFamily}
          font={textBoxFontSize * defferedCoef}
          color={textBoxColor}
          onInput={(e) => setBoxText(e.target.innerHTML)}
          contentEditable
          dangerouslySetInnerHTML={{__html: boxTextRef.current}}
        ></StyledText>
      </DraggableBox>

      <DraggableBox
        position={sliderBoxPosition}
        setPosition={setSliderBoxPosition}
        sizes={sliderBoxSize}
        setSizes={setSliderBoxSize}
        linePosition={sliderBoxLinePosition}
        setLinePosition={setSliderBoxLinePosition}
        parentRef={container}
      >
        <StyledSlider color={sliderLineColor} size={sliderImageSize}>
          {sliderImageList.filter(item => item).map((item, i) => <div key={i}><img src={item.image} alt={item.name}/></div>)}
        </StyledSlider>

        <StyledOption>
          {optionNameList.filter(item => item).map((item, i) =>
            <StyledOptionCell width={optionSizeList[i]} key={i}>
              {optionImageList[i]?.image && <img src={optionImageList[i]?.image} alt={item}/>}
            </StyledOptionCell>)
          }
        </StyledOption>
      </DraggableBox>

    </StyledModule>
  );
};

const StyledModule = styled.div`
  position: absolute;
  top: 5%;
  left: 5%;
  bottom: 5%;
  right: 5%;
  background: ${props => props.backgroundImage ? props.backgroundImage : '#fff'};
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  box-shadow: 0 0 2px 1px rgba(0 0 0 / 30%);
  &.active {
    box-shadow: 0 0 1px 2px #53A451;
  }
`;

const StyledText = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  background: transparent;
  font-family: ${props => props.fontFamily};
  font-size: ${props => 0.9 * props.font + 'vw'} !important;
  line-height: 1.2;
  color: ${props => props.color};
`;

const StyledSlider = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  > div {
    width: 98%;
    height: 3px;
    background: ${props => props.color};
    margin: auto;
    position: relative;
  }
  > div > img {
    height: ${props => `${props.size * 10}px`};
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: ${props => `-${props.size * 10 / 2}px`};
  }
  > div:before {
    content: '';
    background: ${props => props.color};
    width: 13px;
    height: 13px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    margin: auto;
  }
  > div:after {
    content: '';
    background: ${props => props.color};
    width: 13px;
    height: 13px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    margin: auto;
  }
`;

const StyledOption = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: flex;
  > div > img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: ${props => `-${props.size * 10 / 2}px`};
  }
`;

const StyledOptionCell = styled.div `{
  width: ${props => `${props.width}%`};
  border: 1px #ccc dashed;
  background: ${props => props.color};
  position: relative;
}`