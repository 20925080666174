import React from 'react';
import {Button} from '@mui/material';
import styled from 'styled-components';
import {IconButton} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const SelectNumber = ({onChange, number, id, length}) => {
  return (
    <StyledContainer>
      <StyledIconButton disabled={number === 0 ? true : false} onClick={(e) => onChange(number, number - 1, id)}>
        <ArrowDropUpIcon sx={{fontSize: 20}}/>
      </StyledIconButton>
      <StyledNumber>{number + 1}</StyledNumber>
      <StyledIconButton disabled={number + 1 === length ? true : false} onClick={(e) => onChange(number, number + 1, id)}>
        <ArrowDropDownIcon sx={{fontSize: 20}}/>
      </StyledIconButton>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5%;
  height: 80px;
  margin-top: auto;
  margin-bottom: auto;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  user-select: none;
`;

const StyledIconButton = styled(IconButton)`
  color: #000;
  width: 26px;
  height: 26px;
`;

const StyledNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;
