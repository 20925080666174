import React from 'react';
import styled from 'styled-components';
import {Link, useParams} from "react-router-dom";
import {TextModule} from "../Page/Text";

export const Text = ({number, projectData, statData, moduleId, engagements }) => {
    const {projectId} = useParams();

    const letters_count = statData.letters_count / statData.filled_answers_count
    const percentage = statData.filled_answers_count / engagements * 100

    return (
        <StyledContainer>
            <StyledBoxText>{projectData.boxText}</StyledBoxText>
            <Link to={{
                pathname: `/project-report-text-list/${projectId}/${moduleId}/`,
            }}>
                <StyledModule>
                    <TextModule data={projectData} previewMode={true}/>
                </StyledModule>
            </Link>
            <StyledItemContainer>
                <StyledItem>
                    <div>The number of answers</div>
                    <div>{statData.filled_answers_count}</div>
                </StyledItem>
                <StyledItem>
                    <div>Percentage of answers</div>
                    <div>{`${percentage.toFixed(1)}%`}</div>
                </StyledItem>
                <StyledItem>
                    <div>The average number of characters</div>
                    <div>{letters_count.toFixed(2)}</div>
                </StyledItem>
            </StyledItemContainer>
        </StyledContainer>
    );
};

const StyledModule = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  background: ${props => props.backgroundColor ? props.backgroundColor : '#fff'};
`;

const StyledContainer = styled.div`
  padding: 20px 10px;
  width: 300px;
`;

const StyledImg = styled.img`
  width: 100%;
  margin-bottom: 20px;
`;

const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    width: 100%;
  }

  & h4 {
    margin: 10px 0 5px;
  }
`;

const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2px;

  & > div {
    font-size: 15px;
    line-height: 19px;
  }

  & > div:first-child {
    width: 80%;
    padding-right: 10px;
  }
`;

const StyledBoxText = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
`;

