import React, { useRef, useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { CenterLines } from '../UI/CenterLines';
import Draggable from 'react-draggable';
import OpenWithIcon from '@mui/icons-material/OpenWith';

export const DraggableBox = ({
  children,
  position,
  setPosition,
  sizes,
  setSizes,
  linePosition,
  setLinePosition,
  parentRef,
  grid,
  backgroundImage,
  className
}) => {
  const container = useRef(null);

  function toPercentWidth(value) {
    let parentWidth = parentRef.current?.clientWidth || 0;
    return Number(((100 * parseFloat(value)) / parseFloat(parentWidth)).toFixed());
  }

  function toPercentHeight(value) {
    let parentHeight = parentRef.current?.clientHeight || 0;
    return Number(((100 * parseFloat(value)) / parseFloat(parentHeight)).toFixed());
  }

  function toPxWidth(value) {
    let parentWidth = parentRef.current?.clientWidth || 0;
    return Number(((parseFloat(value) * parseFloat(parentWidth)) / 100).toFixed());
  }

  function toPxHeight(value) {
    let parentHeight = parentRef.current?.clientHeight || 0;
    return Number(((parseFloat(value) * parseFloat(parentHeight)) / 100).toFixed());
  }

  useEffect(() => {
    container.current.style.width = `${sizes[0]}%`;
    container.current.style.height = `${sizes[1]}%`;
  }, [sizes]);

  useEffect(() => {
    if (typeof setSizes === 'function') {
      const containerObserver = new ResizeObserver(entries => {
        let w = toPercentWidth(container.current.offsetWidth);
        let h = toPercentHeight(container.current.offsetHeight);
        w = w - (w % 2);
        h = h - (h % 2);

        if (sizes[0] !== w || sizes[1] !== h) {
          setSizes([w - (w % 2), h - (h % 2)]);
        }
      });

      containerObserver.observe(container.current);

      return () => {
        containerObserver.disconnect();
      };
    }
  });

  return (
    <Draggable
      nodeRef={container}
      handle='.handle'
      bounds='parent'
      grid={grid || [1, 1]}
      position={{ x: toPxWidth(position[0]), y: toPxHeight(position[1]) }}
      onDrag={(e, d) =>
        setLinePosition && setLinePosition([toPercentWidth(d.x), toPercentHeight(d.y)])
      }
      onStop={(e, d) => setPosition([toPercentWidth(d.x), toPercentHeight(d.y)])}>
      <StyledContainer
        className={className ? className : ''}
        resize={setSizes ? 'both' : 'none'}
        ref={container}
        backgroundImage={backgroundImage}>
        {linePosition && <CenterLines zIndex='2' sizes={sizes} positions={linePosition} />}
        <div className='handle'>
          <OpenWithIcon sx={{ fontSize: 15 }} />
        </div>
        {children}
      </StyledContainer>
    </Draggable>
  );
};

const StyledContainer = styled.div`
  resize: ${props => props.resize};
  overflow: hidden;
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  background: ${props => (props.backgroundImage ? props.backgroundImage : 'transparent')};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border: 1px dashed #ccc;
  .handle {
    z-index: 1;
    width: 15px;
    height: 15px;
    text-transform: none;
    background: #eee;
    border: 1px solid #ccc;
    color: #000;
    cursor: grab;
    font-size: 10px;
    position: absolute;
    left: 0;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.2;
  }
  .handle:hover {
    opacity: 1;
  }
  .handle:active {
    cursor: grabbing;
  }
`;
