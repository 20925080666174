import React from 'react';
import styled from 'styled-components';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export const SwitchBox = ({title, checked, setChecked}) => {
  return (
    <StyledFormControlLabel control={<Switch checked={checked} onChange={(e) => setChecked(e.target.checked)} />} label={title} color="primary" />
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 0;
    color: #fff;
    padding: 5px 10px;
    width: 100%;
    box-sizing: border-box;
  }
`;
