import {combineReducers, configureStore} from '@reduxjs/toolkit';
import project from './project';

const rootReducer = combineReducers({
  project
});

export const store = configureStore({
  reducer: rootReducer
});
